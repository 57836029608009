import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedService, ToasterService } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';
import { FullScreenDialogService } from '@headpower/components';
import { OAuthService } from 'angular-oauth2-oidc';
import { forkJoin, map, Subject } from 'rxjs';

import { PersonStatus, PersonDetailsShort } from './person-status.model';
import { PersonStatusService } from './person-status.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { PersonStatusDialogComponent } from './person-status-dialog/person-status-dialog.component';



@Component({
    selector: 'app-profile-person-statuses',
    templateUrl: './person-statuses.component.html',
    styleUrls: ['./person-statuses.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonStatusesComponent implements OnInit, OnDestroy {

    @Input() public mobile: boolean = false;

    public loading: boolean = true;
    public partialLoading: boolean = false;
    public statusesAsAppointer?: PersonStatus[];
    public statusesAsDeputy?: PersonStatus[];
    public companyPersons?: PersonDetailsShort[];

    public asAppointerDisplayedColumns: string[] = ['person', 'startDate', 'endDate', 'description', 'absent', 'actions'];
    public asDeputyDisplayedColumns: string[] = ['person', 'startDate', 'endDate', 'description', 'spacer1', 'spacer2'];
    public asDeputySpacerColumns: any[] = [
        { columnDef: 'spacer1', className: 'absent' },
        { columnDef: 'spacer2', className: 'actions' }
    ];

    private currentUserId!: string;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private oAuthService: OAuthService,
        private sharedService: SharedService,
        private personStatusService: PersonStatusService,
        private dialog: MatDialog,
        private fullScreenDialog: FullScreenDialogService,
        private blahService: BlahService,
        private toaster: ToasterService,
        private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.currentUserId = this.oAuthService.getIdentityClaims()['sub'];

        forkJoin([
            this.personStatusService.getStatusesAsAppointer(this.currentUserId),
            this.personStatusService.getStatusesAsDeputy(this.currentUserId),
            this.getCompanyPersons()
        ])
            .subscribe(results => {
                this.statusesAsAppointer = results[0];
                this.statusesAsDeputy = results[1];
                this.companyPersons = results[2];

                this.loading = false;
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public createStatus() {
        const dialogData = {
            creatingNew: true,
            companyPersons: this.companyPersons
        };

        let dialogRef: MatDialogRef<any>;

        if (!this.mobile) {
            dialogRef = this.dialog.open(PersonStatusDialogComponent, {
                width: '600px',
                disableClose: true,
                autoFocus: false,
                data: dialogData
            });
        } else {
            dialogRef = this.fullScreenDialog.open(PersonStatusDialogComponent, {
                autoFocus: false,
                data: {
                    mobile: true,
                    dialogTitle: 'portal.personStatus.addDeputyOrStatus',
                    actionLabel: 'default.save',
                    ...dialogData
                }
            });
        }

        dialogRef.afterClosed()
            .subscribe(result => {
                if (result) {
                    this.toaster.create(this.blahService.blah('portal.personStatus.deputyOrStatusAdded'), {
                        color: 'success'
                    });

                    this.partialLoading = true;
                    this.cdRef.markForCheck();

                    this.fetchStatusesAsAppointer();
                }
            });
    }

    public editStatus(personStatus: PersonStatus) {
        const dialogData = {
            companyPersons: this.companyPersons,
            personStatus
        };

        let dialogRef: MatDialogRef<any>;

        if (!this.mobile) {
            dialogRef = this.dialog.open(PersonStatusDialogComponent, {
                width: '600px',
                disableClose: true,
                autoFocus: false,
                data: dialogData
            });
        } else {
            dialogRef = this.fullScreenDialog.open(PersonStatusDialogComponent, {
                autoFocus: false,
                data: {
                    mobile: true,
                    dialogTitle: 'portal.personStatus.editDeputyOrStatus',
                    actionLabel: 'default.save',
                    ...dialogData
                }
            });
        }

        dialogRef.afterClosed()
            .subscribe(result => {
                if (result) {
                    this.toaster.create(this.blahService.blah('default.saveSucceeded'), {
                        color: 'success'
                    });

                    this.partialLoading = true;
                    this.cdRef.markForCheck();

                    this.fetchStatusesAsAppointer();
                }
            });
    }

    public deleteStatus(personStatusId: string) {
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'portal.personStatus.deleteDeputyOrStatus',
                message: 'portal.personStatus.deleteDeputyOrStatusConfirmation'
            }
        })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.partialLoading = true;
                    this.cdRef.markForCheck();

                    this.personStatusService.deleteStatus(personStatusId)
                        .subscribe(() => {
                            this.toaster.create(this.blahService.blah('portal.personStatus.deputyOrStatusDeleted'), {
                                color: 'success'
                            });

                            this.fetchStatusesAsAppointer();
                        });
                }
            });
    }

    private getCompanyPersons() {
        return this.sharedService.getCompanyUsers()
            .pipe(
                map(result => (result
                    .map(o => ({
                        Id: o.Id,
                        Name: o.Name
                    })) as PersonDetailsShort[])
                    // Remove current user from the list
                    .filter(o => o.Id !== this.currentUserId)
                    // Sort by name ascending
                    .sort((a, b) => a.Name.localeCompare(b.Name)))
            );
    }

    private fetchStatusesAsAppointer() {
        this.personStatusService.getStatusesAsAppointer(this.currentUserId)
            .subscribe(result => {
                this.statusesAsAppointer = result;

                this.partialLoading = false;
                this.cdRef.markForCheck();
            });
    }
}
