import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm, ValidationErrors } from '@angular/forms';
import { LayoutBreakpointService } from '@headpower/layout';
import { Subject, takeUntil } from 'rxjs';
import { CreateUserInvitationEmailDto, OldPortalService } from '../../services/old-portal.service';

@Component({
    selector: 'app-old-invite-person-page',
    templateUrl: './old-invite-person-page.component.html',
    styleUrls: ['./old-invite-person-page.component.css']
})
export class OldInvitePersonPageComponent implements OnInit, OnDestroy {

    @ViewChild('invitationFg') invitationFormRef: NgForm;

    handsetLayout: boolean;
    invitationForm: UntypedFormGroup;
    submitted: boolean = false;
    submitting: boolean = false;
    invitationNotSentMessage: string;

    private readonly destroy$: Subject<void> = new Subject();

    constructor(private readonly portalService: OldPortalService,
        private readonly layoutBreakpoint: LayoutBreakpointService,
        private readonly fb: UntypedFormBuilder) { }

    ngOnInit() {
        this.layoutBreakpoint.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.handsetLayout = result.handset)

        this.invitationForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Sets related properties to indicating submitted
     */
    private setSubmitted() {
        this.submitted = true;
        this.submitting = false;
    }

    /**
     * Indicates can user invitation be sent
     * @param data any type for metadata
     */
    private canSendUserInvitation(data: any): boolean {
        const state = data.state as string;
        let canSend = false;

        switch (state) {
            case 'userExistedInCompany':
                this.invitationNotSentMessage = 'portal.personIsAlreadyCompanyUser';
                break;
            case 'deletedUserExistedInCompany':
                this.invitationNotSentMessage = 'portal.deletedPersonIsAlreadyCompanyUser';
                break;
            case 'userDoesntExistInCompany':
            case 'userIdentityNotFound':
                canSend = true;
                break;
        }

        return canSend;
    }

    errors(control: string): ValidationErrors {
        return this.invitationForm.get(control).errors;
    }

    onSubmit() {
        // Submit only if form is valid
        if (this.invitationForm.invalid) return;

        this.submitting = true;
        this.invitationNotSentMessage = undefined;

        const invitationEmail = this.invitationForm.value.email as string;

        let createUserInvitationEmailDto = new CreateUserInvitationEmailDto;

        createUserInvitationEmailDto.UserInvitationReceiverEmail = invitationEmail;

        this.portalService.checkIdentityExistence(invitationEmail)
            .subscribe({
                next: (data) => {

                    if (this.canSendUserInvitation(data)) {
                        this.portalService.createUserInvitation(createUserInvitationEmailDto)
                            .subscribe({
                                next: (data) => {
                                    this.setSubmitted();
                                },
                                error: () => {
                                    this.invitationNotSentMessage = 'default.unknownError';
                                    this.setSubmitted();
                                }
                            })
                    } else {
                        this.setSubmitted();
                    }
                },
                error: (error) => {
                    if (error.error != undefined) {
                        if (error.error.Message != undefined) {
                            if (error.error.Message === "default.invalidEmail") {
                                this.invitationNotSentMessage = error.error.Message;
                            }
                        }
                        else {
                            this.invitationNotSentMessage = 'default.unknownError';
                        }
                    }
                    else {
                        this.invitationNotSentMessage = 'default.unknownError';
                    }
                    this.setSubmitted();
                }
            });
    }

    resetInvitationForm() {
        this.submitted = false;
        this.submitting = false;
        this.invitationNotSentMessage = undefined;

        // NgForm reference is used to reset the form. (https://github.com/angular/material2/issues/4190)
        this.invitationFormRef.resetForm({
            email: ''
        });
    }
}
