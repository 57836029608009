import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/app.config';
import { UserInvitation, UserInvitationResult } from './user-invitation.model';


@Injectable()
export class UserInvitationsService {

    private portalBackendUrl: string;

    constructor(
        private http: HttpClient,
        private config: AppConfig) {

        this.portalBackendUrl = this.config.backendPortalUri;
    }

    public getCurrentUserUserInvitations(): Observable<UserInvitation[]> {
        const url = `${this.portalBackendUrl}Portal.WebAPI/UserInvitations/GetCurrentUserUserInvitations`;
        return this.http.get<UserInvitation[]>(url);
    }

    public acceptUserInvitation(userInvitationId: string): Observable<UserInvitationResult> {
        const url = `${this.portalBackendUrl}Portal.WebAPI/UserInvitations/AcceptInvitation?invitationId=${userInvitationId}`;
        return this.http.post<UserInvitationResult>(url, null);
    }

    public rejectUserInvitation(userInvitationId: string): Observable<UserInvitationResult> {
        const url = `${this.portalBackendUrl}Portal.WebAPI/UserInvitations/RejectInvitation?invitationId=${userInvitationId}`;
        return this.http.post<UserInvitationResult>(url, null);
    }

}
