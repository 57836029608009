import { Component } from '@angular/core';
import { FeatureFlagService } from '@headpower/featureflags';

@Component({
    selector: 'invite-person-page-wrapper',
    template: `
    @if(ff_newAccount === true) {
        <app-invite-person-page></app-invite-person-page>
    }
    @if(ff_newAccount === false) {
        <app-old-invite-person-page></app-old-invite-person-page>
    }     
    `
})
export class InvitePersonPageWrapperComponent {

    public ff_newAccount: boolean;

    constructor(ffService: FeatureFlagService) {
        ffService.isFeatureAvailable('Account.NewAccount')
            .subscribe(res => {
                this.ff_newAccount = res;
            })
    }
}
