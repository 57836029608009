export class Product {
    public name: string;
    public url: string;
    public documentId: number;
    public timesUsedInLast30Days: number;
    public lastAccess: string;
    public groupName: string;
    public accessGroupName
    public priority: number;
    public accessUid: number;
    public personFavoriteApplication: number;
    public domain: number;
    public productIcon: string;

    constructor() {
        this.name = '';
        this.url = '';
        this.documentId = 0;
        this.timesUsedInLast30Days = 0;
        this.lastAccess = '';
        this.groupName = '';
        this.accessGroupName = '';
        this.priority = 0;
        this.accessUid = 0;
        this.personFavoriteApplication = 0;
        this.domain = 0;
        this.productIcon = '';
    }
}
