import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { SortProductsPipe } from './pipes/sort-products/sort-products.pipe';
import { FilterTagsPipe } from './pipes/filter-tags/filter-tags.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [
        SortProductsPipe,
        FilterTagsPipe,
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
        SortProductsPipe,
        FilterTagsPipe,
        ConfirmDialogComponent
    ]
})
export class SharedModule { }
