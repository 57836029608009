import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '../notification';
import { OldPortalService } from 'src/app/services/old-portal.service';

@Component({
    selector: 'notification-important-news-dialog',
    templateUrl: './notification-important-news-dialog.component.html'
})
export class ImportantNewsDialogComponent implements OnInit {

    @Output() updateRequest = new EventEmitter<Notification>();

    public fullScreenDialog: boolean;
    public title: string;
    public content: string;
    public notification: Notification;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private portalService: OldPortalService,
        public dialogRef: MatDialogRef<ImportantNewsDialogComponent>) { }

    ngOnInit(): void {
        this.fullScreenDialog = this.data.fullScreenDialog as boolean;
        this.notification = this.data.notification;
        this.title = this.data.notification.title;
        this.content = this.data.notification.content;

        this.dialogRef.afterClosed()
            .subscribe(result => {
                this.portalService.markNotificationAsRead(this.notification.objectId).subscribe(data => {
                    this.notification.isUnchecked = false
                    this.updateRequest.emit(this.notification);
                }, error => {
                    console.error('Failed to mark notification as read (probably was marked already)');
                });
            });
    }

    public confirm(): void {
        this.dialogRef.close();
    }
}
