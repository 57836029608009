.group-selector {
    padding: 0.8rem 1.6rem 0 1.6rem;
}

.select-group-panel {
    overflow: hidden !important;
    max-height: none !important;
}

.favorite-color {
    color: #EC9142;
}

.default-color {
    color: #CBCCCB;
}

.no-products-note {
    font-weight: 300;
}

.product-list {
    display: grid;
    column-gap: 6px;
    grid-row-gap: 6px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.link-list {
    padding: 16px;
    display: grid;
    column-gap: 6px;
    grid-row-gap: 6px;
    grid-template-columns: 100%;
}

.mat-card-product {
    cursor: pointer;
    padding: 5px;
    padding-bottom: 0px;
}

.mat-card-product:hover {
    background-color: #e8e8e8;
}

.product-container {
    padding: 0 !important;
    display: grid;
    grid-template-columns: 48px auto 24px;
    grid-column-gap: 5px;
    margin-bottom: 0px;
    align-items: center;
}

.product-container.mat-mdc-card-content {
    display: grid;
    grid-template-columns: 48px auto 24px;
    grid-column-gap: 5px;
    margin-bottom: 0px;
}

.product-image {
    grid-column-start: 1;
    grid-row-start: 1;
    max-height: 48px;
    max-width: 48px;

}

.image-product {
    max-height: 80%;
    max-width: 80%;
    align-self: flex-start;
}

.favorite-icon {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    justify-content: center;
    align-items: center;
}

.product-name {
    font-size: 18px;
    font-weight: 400;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    color: rgba(0, 0, 0, 0.87);
    word-break: break-word;
}

.product-path {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
}

@media only screen and (min-width: 1800px) {
    .link-list {
        grid-template-columns: 48% 48%;
    }
}