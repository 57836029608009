<div class="grid-wrapper">
    <div class="grid-section padding">
        <ng-template #formTemplate>

            <form [formGroup]="invitationForm"
                #invitationFg="ngForm"
                (ngSubmit)="onSubmit()"
                novalidate>

                <!-- Email -->
                <mat-form-field>
                    <mat-label>{{ 'default.email' | blah }}</mat-label>
                    <input matInput
                        id="user-invitation-email"
                        maxlength="100"
                        [readonly]="submitting || submitted"
                        formControlName="email">
                    <mat-error *ngIf="errors('email')?.required">
                        {{ 'default.validation.required' | blah }}
                    </mat-error>
                    <mat-error *ngIf="errors('email')?.email && !errors('email')?.required">
                        {{ 'default.validation.invalidEmail' | blah }}
                    </mat-error>
                </mat-form-field>

                <div class="name-container">
                    <!-- FirstName -->
                    <mat-form-field>
                        <mat-label>{{ 'default.firstName' | blah }}</mat-label>
                        <input matInput
                            id="user-invitation-firstname"
                            maxlength="100"
                            [readonly]="submitting || submitted"
                            formControlName="firstname">
                        <mat-error *ngIf="errors('firstname')?.required || errors('firstname')?.whitespace">
                            {{ 'default.validation.required' | blah }}
                        </mat-error>
                    </mat-form-field>

                    <!-- LastName -->
                    <mat-form-field>
                        <mat-label>{{ 'default.lastName' | blah }}</mat-label>
                        <input matInput
                            id="user-invitation-lastname"
                            maxlength="100"
                            [readonly]="submitting || submitted"
                            formControlName="lastname">
                        <mat-error *ngIf="errors('lastname')?.required || errors('firstname')?.whitespace">
                            {{ 'default.validation.required' | blah }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="buttons-divider"></div>

                <hpo-button class="form-button"
                    type="raised"
                    color="primary"
                    id="user-invitation-send-button"
                    [disabled]="submitting || submitted">
                    {{ 'default.send' | blah }}
                </hpo-button>
                <div class="clearfix"></div>

            </form>

        </ng-template>


        <hpo-notification *ngIf="submitted && invitationNotSentMessage.success"
            color="primary"
            [closeable]="true"
            id="user-invitation-success-notification"
            (closed)="resetInvitationForm()">

            {{ 'portal.sendingInvitationSucceeded' | blah }}
        </hpo-notification>

        <hpo-notification *ngIf="submitted && !invitationNotSentMessage.success"
            [closeable]="true"
            [color]="invitationNotSentMessage.type === 'error' ? 'warn' : 'none'"
            class="notification"
            id="user-invitation-fail-notification"
            (closed)="resetInvitationForm()">
            <div class="notification-content">
                <div>
                    <div class="with-icon">
                        @if (invitationNotSentMessage.type === 'default') {
                        <span class="material-symbols-outlined warn prefix-icon">
                            report
                        </span>
                        }
                        <b> {{( invitationNotSentMessage.type === 'error' ? 'portal.sendingInvitationFailed' : 'portal.invitationNotSent' ) | blah
                            }}</b>
                    </div>
                </div>
                <br><br>
                {{ invitationNotSentMessage.message ? (invitationNotSentMessage.message | blah) : '' }}
            </div>
        </hpo-notification>

        <div class="content-padding desktop handset-horizontal">

            <!-- Desktop layout -->
            <ng-container *ngIf="!handsetLayout">

                <hpo-card>
                    <hpo-card-header color="primary">
                        {{ 'portal.invitePersonAsUser' | blah }}
                    </hpo-card-header>

                    <hpo-card-content>

                        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>

                    </hpo-card-content>
                </hpo-card>

            </ng-container>
            <!-- /Desktop layout -->

            <!-- Handset layout -->
            <ng-container *ngIf="handsetLayout">

                <h6 class="leading">
                    {{ 'portal.invitePersonAsUser' | blah }}
                </h6>

                <ng-container *ngTemplateOutlet="formTemplate"></ng-container>

            </ng-container>
            <!-- /Handset layout -->
        </div>

    </div>
</div>