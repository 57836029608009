<div class="grid-wrapper">
    <div class="grid-section padding">
        <ng-template #formTemplate>

            <form [formGroup]="invitationForm"
                #invitationFg="ngForm"
                (ngSubmit)="onSubmit()"
                novalidate>

                <!-- Email -->
                <mat-form-field>
                    <mat-label>{{ 'default.email' | blah }}</mat-label>
                    <input matInput
                        [readonly]="submitting || submitted"
                        formControlName="email">
                    <mat-error *ngIf="errors('email')?.required">
                        {{ 'default.validation.required' | blah }}
                    </mat-error>
                    <mat-error *ngIf="errors('email')?.email && !errors('email')?.required">
                        {{ 'default.validation.invalidEmail' | blah }}
                    </mat-error>
                </mat-form-field>

                <div class="buttons-divider"></div>

                <hpo-button class="form-button"
                    type="raised"
                    color="primary"
                    [disabled]="submitting || submitted">
                    {{ 'default.send' | blah }}
                </hpo-button>
                <div class="clearfix"></div>

            </form>

        </ng-template>


        <hpo-notification *ngIf="submitted && !invitationNotSentMessage"
            color="primary"
            [closeable]="true"
            (closed)="resetInvitationForm()">

            {{ 'portal.sendingInvitationSucceeded' | blah }}
        </hpo-notification>

        <hpo-notification *ngIf="submitted && invitationNotSentMessage"
            color="warn"
            [closeable]="true"
            (closed)="resetInvitationForm()">

            {{ 'portal.sendingInvitationFailed' | blah }}:
            <br><br>
            <b>{{ invitationNotSentMessage | blah }}</b>
        </hpo-notification>

        <div class="content-padding desktop handset-horizontal">

            <!-- Desktop layout -->
            <ng-container *ngIf="!handsetLayout">

                <hpo-card>
                    <hpo-card-header color="primary">
                        {{ 'portal.invitePersonAsUser' | blah }}
                    </hpo-card-header>

                    <hpo-card-content>

                        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>

                    </hpo-card-content>
                </hpo-card>

            </ng-container>
            <!-- /Desktop layout -->

            <!-- Handset layout -->
            <ng-container *ngIf="handsetLayout">

                <h6 class="leading">
                    {{ 'portal.invitePersonAsUser' | blah }}
                </h6>

                <ng-container *ngTemplateOutlet="formTemplate"></ng-container>

            </ng-container>
            <!-- /Handset layout -->
        </div>

    </div>
</div>