import dayjs from 'dayjs';

// Import plugins
import localeDataPlugin from 'dayjs/plugin/localeData';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import objectSupportPlugin from 'dayjs/plugin/objectSupport';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import utcPlugin from 'dayjs/plugin/utc';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear';
import weekdayPlugin from 'dayjs/plugin/weekday';

// Import locales
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';

// Set plugins
dayjs.extend(localeDataPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(objectSupportPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(weekOfYearPlugin);
dayjs.extend(weekdayPlugin);