import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(reason => {
    // Redirect user to error page
    const errorMessage = encodeURIComponent(btoa(reason));
    const errorUrl = `${document.baseURI}error.html#${errorMessage}`;

    window.location.href = errorUrl;
});
