import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import localeFi from '@angular/common/locales/fi';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppOAuthStorage, AuthorizationInterceptor } from '@headpower/angular-oauth2-oidc-extensions';

import { getOldTheme, HpoLayoutModule, ThemeService, UserProfileModule } from '@headpower/layout';
import { BlahModule, BlahService } from '@headpower/blah-ng';
import { BottomNavModule, NotificationModule, AddSearchModule } from '@headpower/components';
import '@headpower/components/dayjs-loader';

import { AppConfig } from './app.config';
import { ProductsModule } from './products/products.module';
import { NotificationsModule } from './notifications/notifications.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { InvitePersonModule } from './invite-person/invite-person.module';
import { HelpdeskModule } from './helpdesk/helpdesk.module';

/* Material components */
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { HomeComponent } from './home/home.component';
import { AccessDeniedComponent } from './access-denied.component';
import { ProductRedirectComponent } from './product-redirect/product-redirect.component';

import { HpoErrorHandler } from './shared/handlers/hpo-errorhandler.service';
import { InfoItemModule } from './info-item/info-item.module';
import { environment } from '../environments/environment';
import { BypassServiceWorkerInterceptor } from './bypass-service-worker.interceptor';
import { ProfileModule } from './profile/profile.module';
import { AuthModule } from './auth/auth.module';

export function createOAuthStorage() {
    return new AppOAuthStorage('headpower_web_app', 'portal');
}

export function initConfig(config: AppConfig) {
    return () => config.load(environment.version);
}

export function initBlahFactory(blahService: BlahService): Function {
    return () => blahService.init({
        appVersion: environment.version,
        resourceNames: [
            'default',
            'portal',
            'components',
            'layout',
            'error'
        ]
    });
}

export function initTheme(themeService: ThemeService): Function {
    return () => themeService.init(getOldTheme());
}

registerLocaleData(localeFi, 'fi-FI');

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        HomeComponent,
        AccessDeniedComponent,
        ProductRedirectComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HpoLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        ProductsModule,
        NotificationsModule,
        InvitePersonModule,
        HelpdeskModule,
        AppRoutingModule,
        InfoItemModule,
        OAuthModule.forRoot(),
        BottomNavModule,
        NotificationModule,
        AddSearchModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        BlahModule,
        MatCardModule,
        UserProfileModule,
        ProfileModule,
        AuthModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        })
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
        { provide: LOCALE_ID, useValue: 'fi-FI' },
        { provide: HTTP_INTERCEPTORS, useClass: BypassServiceWorkerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        { provide: OAuthStorage, useFactory: createOAuthStorage },
        { provide: AppOAuthStorage, useFactory: createOAuthStorage },
        { provide: ErrorHandler, useClass: HpoErrorHandler },
        {
            // Initialize Blah translation service
            provide: APP_INITIALIZER,
            useFactory: initBlahFactory,
            multi: true,
            deps: [BlahService]
        },
        { provide: APP_INITIALIZER, useFactory: initTheme, deps: [ThemeService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
