import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlahModule } from '@headpower/blah-ng';

import { AuthCallbackComponent } from './callback/callback.component';

@NgModule({
    declarations: [
        AuthCallbackComponent
    ],
    imports: [
        CommonModule,
        BlahModule
    ],
    exports: [
        AuthCallbackComponent
    ],
    providers: [
    ]
})
export class AuthModule { }
