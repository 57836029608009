import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filters out all HTML tags from a string
 */
@Pipe({
    name: 'filterTags'
})
export class FilterTagsPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;

        return value
            .replace(/\n/g, '')           // Remove all new line characters
            .replace(/<br[\s\/]*>/g, ' ') // Replace all new line tags with whitespace
            .replace(/<[^>]*>/g, '');     // Remove all tags
    }
}
