import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ButtonModule } from '@headpower/components';
import { BlahModule } from '@headpower/blah-ng';
import { InfoItemComponent } from './info-item/info-item.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [
        InfoItemComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        ButtonModule,
        BlahModule,
        SharedModule
    ],
    exports: [
        InfoItemComponent
    ]
})
export class InfoItemModule { }
