import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { BlahModule } from '@headpower/blah-ng';
import { HpoLayoutModule, SharedDirectivesModule } from '@headpower/layout';
import { SpinnerModule, ButtonModule, CardModule, NotificationModule } from '@headpower/components';

import { HelpdeskComponent } from './helpdesk.component';

@NgModule({
    declarations: [
        HelpdeskComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BlahModule,
        MatInputModule,
        MatCheckboxModule,
        HpoLayoutModule,
        SharedDirectivesModule,
        SpinnerModule,
        ButtonModule,
        CardModule,
        NotificationModule
    ],
    exports: [
    ]
})
export class HelpdeskModule { }
