import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject, ErrorHandler, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkNewsAsReadParameters, OldPortalService } from '../../services/old-portal.service';
import { Notification } from '../notification';
import { LayoutBreakpointService, SharedService } from '@headpower/layout';
import { MatDialog } from '@angular/material/dialog';
import { ImportantNewsDialogComponent } from '../important-notification/notification-important-news-dialog.component';
import { Product } from '../../products/products-list/product';
import { Subject } from 'rxjs';
import { takeUntil, flatMap } from 'rxjs/operators';
import { FullScreenDialogService } from '@headpower/components';
import { BlahService } from '@headpower/blah-ng';
import { OAuthService } from 'angular-oauth2-oidc';

export class FilterItem {
    id: number;
    name: string;
    selected: boolean;
    selectable: boolean;
}

@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {

    @ViewChild('productFilterInput') productInput: ElementRef<HTMLInputElement>;

    // Filter properties
    products: Array<Product> = new Array<Product>();
    filteredProducts: Array<FilterItem> = [];
    filter: Array<FilterItem> = [];
    oldFilter = '';
    selectedProductNames: string = '';

    @Input() onPage: number;
    @Input() openedNotification: Notification;
    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    claims: any;
    userId: string = '';
    loading: boolean = false;
    nonPerson: boolean = false;
    notificationItems: Array<Notification> = [];
    uncheckedNews: Array<Notification> = [];
    selectedNotification: Notification;
    notificationIsSelected: boolean = false;
    unReadNotificationsCount: number;
    currentPage: number = 1;
    noOfPages: number = 1;
    currentLocale: string;
    handsetLayout: boolean;

    private allNotificationItems: Array<Notification> = [];
    private unexpiredNews: Array<Notification> = [];
    private itemsPerPage: number = 10;

    private destroy$: Subject<void> = new Subject();

    constructor(private portalService: OldPortalService,
        private oAuthService: OAuthService,
        private layoutBreakpoint: LayoutBreakpointService,
        private sharedService: SharedService,
        private fullScreenDialog: FullScreenDialogService,
        private blahService: BlahService,
        public dialog: MatDialog,
        @Inject(ErrorHandler) public errorHandler: any,
        private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.claims = this.oAuthService.getIdentityClaims();

        this.loading = true;

        if (this.onPage) {
            this.currentPage = this.onPage;
        }

        this.blahService.localeChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.getUnexpiredNews());

        this.layoutBreakpoint.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.handsetLayout = result.handset);

        this.getUnexpiredNews();

        if (!this.handsetLayout) {
            this.portalService.getProductData()
                .subscribe(productData => this.parseProducts(this.products, productData));
        } else {
            this.sharedService.getApplicationData(this.currentLocale)
                .subscribe(products => this.parseProducts(this.products, products));
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private getUnexpiredNews() {
        this.currentLocale = this.blahService.currentLocale;

        //Clears all notification items and news (needed in case if language is changed)
        this.allNotificationItems = [];
        this.unexpiredNews = [];
        this.products = [];

        this.portalService.getAllNews(this.currentLocale.substr(0, 2)).pipe(flatMap(data => {
            this.parseNotifications(this.unexpiredNews, data.value);
            return this.getUncheckedNews();
        })).subscribe(data => {
            this.parseNotifications(this.uncheckedNews, data.value);
            this.initNotifications();

            this.loading = false;
        }, error => {
            this.errorHandler.handleError(error);
        },
            // Remove products with no current news or notications from filter list and add products with those news
            () => {
                this.products = this.products.filter(({ accessUid }) => this.allNotificationItems.some(x => x.productId == accessUid));
                this.products.forEach(prod => {
                    var filter = new FilterItem();
                    filter.id = prod.accessUid;
                    filter.name = prod.name;
                    filter.selectable = true;
                    filter.selected = false;
                    this.filteredProducts.push(filter);
                });
            });
    }

    private parseNotifications(array: Array<Notification>, notifications: any) {
        for (let notification of notifications) {
            let newNotification = new Notification();
            newNotification.objectId = notification.ObjectId;
            newNotification.title = notification.Title;
            // FYI: Content cannot be modified by user
            newNotification.content = this.sanitizer.bypassSecurityTrustHtml(notification.Content);
            newNotification.created = notification.Created;
            newNotification.startDate = notification.StartDate;
            newNotification.eventId = notification.EventId;
            newNotification.productId = notification.ProductId;
            newNotification.important = notification.Important;
            newNotification.productIcon = "https://static.headpower.fi/icons/" + (notification.GroupName !== "" && notification.GroupName !== null ? notification.GroupName : 'headpower.svg');
            newNotification.productName = notification.AccessName;

            array.push(newNotification);
        }
    }

    private parseProducts(array: Array<any>, products: any) {
        for (const app of products) {
            const newProduct = new Product();
            newProduct.documentId = app.DocumentId;
            newProduct.groupName = app.GroupName;
            newProduct.lastAccess = app.LastAccess;
            newProduct.name = app.AccessName;
            newProduct.priority = app.AccessPriority;
            newProduct.accessUid = app.Access_UID;
            newProduct.domain = app.Domain;
            newProduct.priority = app.AccessPriority;
            newProduct['selected'] = false;
            // Prevent duplicate products
            if (!array.some(p => p.accessUid === app.Access_UID)) {
                array.push(newProduct);
            }
        }
        array.sort((a, b) => a.name.localeCompare(b.name));
    }

    private initNotifications() {
        this.unexpiredNews.forEach(val => {
            if (this.uncheckedNews.some(n => n.eventId == val.eventId)) {
                val.isUnchecked = true;
                val.isSelected = false;
                if (val.important) {
                    // Show only Portal related important news dialog
                    if (val.productId === 107) {
                        if (this.claims) {
                            this.userId = this.claims['sub'];
                        }

                        this.portalService.getUserInfo(this.userId).subscribe(userData => {
                            this.nonPerson = userData.NonPerson;
                            if (!this.nonPerson) {
                                this.openImportantNewsDialog(val);
                            }

                        }, error => {
                            this.errorHandler.handleError(error);
                        });
                    }
                }
            }

            this.allNotificationItems.push(val);
        });

        this.noOfPages = Math.ceil(this.allNotificationItems.length / this.itemsPerPage);
        this.notificationItems = this.allNotificationItems.slice(0, 5);

        this.unReadNotificationsCount = this.allNotificationItems.filter((x, i) => { return x.isUnchecked; }).length;

        this.changePage(this.currentPage);
    }

    private changePage(page) {
        if (this.filter.length > 0) {
            this.notificationItems = this.allNotificationItems.filter(({ productId }) => this.filter.some(x => x.id == productId)).slice((page - 1) * this.itemsPerPage, (page * this.itemsPerPage));
        } else {
            this.notificationItems = this.allNotificationItems.slice((page - 1) * this.itemsPerPage, (page * this.itemsPerPage));
        }
        this.pageChange.emit(page);
    }

    private getUncheckedNews() {
        return this.portalService.getAllUncheckedNews(this.currentLocale.substr(0, 2));
    }

    private _filter(value: string): Product[] {
        this.oldFilter = value;
        if (value || value !== '') {
            return this.products.filter(product => product.name.toLowerCase().indexOf(value.toLocaleLowerCase()) === 0);
        } else {
            return this.products;
        }
    }

    get fadeState(): string {
        return this.currentPage.toString();
    }

    get slideState(): string {
        return this.notificationIsSelected ? 'right' : 'left';
    }

    /**
    * Marks news as read
    */
    public markNewsAsRead() {
        let markNewsAsReadParameters = new MarkNewsAsReadParameters();

        if (this.unReadNotificationsCount > 0) {
            this.portalService.markNewsAsRead(markNewsAsReadParameters)
                .subscribe(
                    data => {

                        this.allNotificationItems.forEach(element => {
                            element.isUnchecked = false;
                        });

                        this.uncheckedNews = [];

                        this.unReadNotificationsCount = 0;

                    }, error => {
                        this.errorHandler.handleError(error);
                    });
        }
    }

    openNotification(notification: Notification) {
        if (notification) {
            this.notificationItems.forEach(val => val.isSelected = false);
            notification.isSelected = true;

            if (notification.isUnchecked) {
                this.portalService.markNotificationAsRead(notification.objectId).subscribe(data => {
                }, error => {
                    console.error('Failed to mark notification as read (probably was marked already)');
                });

                notification.isUnchecked = false;

                this.unReadNotificationsCount = this.unReadNotificationsCount - 1;

                // Remove from unchecked array as well
                this.uncheckedNews = this.uncheckedNews.filter(news => news.eventId != notification.eventId);
            }

            this.selectedNotification = notification;
            this.notificationIsSelected = true;
        }
    }

    /**
    * Open important news dialog and
    * marks notification as read
    */
    openImportantNewsDialog(notification: Notification): void {
        const dialogConfig = {
            width: !this.handsetLayout ? '800px' : undefined,
            maxWidth: !this.handsetLayout ? '100vw' : undefined,
            maxHeight: !this.handsetLayout ? '90vh' : undefined,
            disableClose: true,
            autoFocus: false,
            data: {
                fullScreenDialog: this.handsetLayout,
                notification: notification,

                // Full-screen dialog properties
                dialogTitle: 'portal.news.importantNews'
            }
        };

        if (this.handsetLayout) {
            this.fullScreenDialog.open(ImportantNewsDialogComponent, dialogConfig);
        } else {
            this.dialog.open(ImportantNewsDialogComponent, dialogConfig);
        }
    }

    next() {
        if (this.currentPage < this.noOfPages) {
            this.changePage(++this.currentPage);
        }
    }

    prev() {
        if (this.currentPage > 1) {
            this.changePage(--this.currentPage);
        }
    }

    toggleSelection(product: FilterItem) {
        if (product) {
            const newSelected: FilterItem[] = [];

            if (this.filter.find((t) => t.id === product.id)) {
                const index = this.filter.findIndex((t) => t.id === product.id);
                this.filter.splice(index, 1);
            } else {
                this.filter.push(product);
            }

            this.filteredProducts.forEach(prod => {
                var newProd = new FilterItem();
                newProd.id = prod.id;
                newProd.name = prod.name;
                newProd.selectable = prod.selectable;
                newProd.selected = this.filter.find((t) => t.id === prod.id) !== undefined;

                newSelected.push(newProd);

            });

            this.filteredProducts = newSelected;
            this.filterNotifications();
        }
    }

    filterNotifications() {
        if (this.filter.length > 0) {
            let unPagedNotifications = this.allNotificationItems.filter(({ productId }) => this.filter.some(x => x.id == productId));

            this.currentPage = 1;
            this.noOfPages = Math.ceil(unPagedNotifications.length / this.itemsPerPage);
            this.notificationItems = unPagedNotifications.splice(0, this.itemsPerPage);
        } else {
            this.allNotificationItems = [];
            this.initNotifications();
        }
    }
}
