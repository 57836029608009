<hpo-layout *ngIf="isLoggedIn"
    [currentUser]="currentUser"
    [productId]="productId"
    [deputyFeatureProductId]="deputyFeatureProductId"
    [claims]="claims"
    [environmentVariables]="environmentVariables"
    [locationUri]="locationUri"
    [menuItems]="menuItems"
    [productionMode]="productionMode"
    [menuIsHidden]="menuIsHidden"
    [accountBaseUri]="accountBaseUri"
    [portalBaseUri]="portalBaseUri"
    [backendAccountUri]="backendAccountUri"
    [backendPortalUri]="backendPortalUri"
    applicationName="Portal"
    applicationNameShort="Portal"
    [applicationSearchTemplate]="applicationSearchContent"
    [appVersion]="appVersion"
    (logoutClicked)="logOut()">

    <ng-template #applicationSearchContent>
        <div [ngClass]="{'centered': !mobile}">
            <div *ngIf="!searchActive && mobile"
                id="add-search-container-handset">
                <div id="task-search-icon"
                    class="app-bar-button"
                    (click)="toggleSearch()">
                    <mat-icon>search</mat-icon>
                </div>
            </div>

            <div *ngIf="searchActive || !mobile"
                id="add-search-container"
                [ngClass]="{'add-search-container-handset': mobile,'add-search-container-desktop': !mobile}">
                <div id="add-search-input">
                    <AddSearch id="add-search"
                        placeholder="{{'default.searchInstructions' | blah}}"></AddSearch>
                </div>
                <div *ngIf="mobile"
                    id="add-search-close"
                    (click)="toggleSearch()">
                    <mat-icon>close</mat-icon>
                </div>
            </div>
        </div>
    </ng-template>

</hpo-layout>

<div *ngIf="!isLoggedIn">
    <router-outlet></router-outlet>
</div>