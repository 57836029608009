import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';


export class UserInfo {

    FirstName: string;
    LastName: string;
    Position: string;
    Phone: string;
    Language: string;
    TimeZone: string;

    ContactInfoPermit: boolean;
    MassMail: boolean;
    DefaultBusinessDomain: string;

    LastLogin: string;
}

export class UpdateUserData {
    FirstName: string;
    LastName: string;
    Position: string;
    Phone: string;
    Language: string;
    TimeZone: string;

    ContactInfoPermit: boolean;
    MassMail: boolean;
    DefaultBusinessDomain: string;
}

export class UserIdentity {
    Email: string;

    LastLogin: string;
    LastPasswordChange: string;
    LastActivation: string;
    EulaAccepted: string;
    PersonDataPermit: string;

    AlternativeUserName: string;
}

export class UpdateIdentityData {
    AlternativeUserName: string
}


@Injectable()
export class ProfileService {


    constructor(private http: HttpClient, private config: AppConfig) { }

    //#region User

    public getUserInfo(): Observable<UserInfo> {
        const requestUrl = this.config.backendPortalUri + `Portal.WebAPI/Users/GetCurrentUser`;

        return this.http.get<UserInfo>(requestUrl);
    }

    public updateUserInfo(updateValues: UpdateUserData): Observable<UserInfo> {
        const requestUrl = this.config.backendPortalUri + `Portal.WebAPI/Users/UpdateCurrentUser`;

        return this.http.post<UserInfo>(requestUrl, updateValues);
    }

    //#endregion User

    //#region Identity

    public getUserIdentity(): Observable<UserIdentity> {
        const requestUrl = this.config.backendPortalUri + `Portal.WebAPI/Identities/GetCurrentIdentity`;

        return this.http.get<UserIdentity>(requestUrl);
    }

    /**
    * Changes alternative user name
    * @param altUsername Alternative user name
    */
    public updateUserIdentity(updateValues: UpdateIdentityData): Observable<UserIdentity> {
        const requestUrl = this.config.backendPortalUri + `Portal.WebAPI/Identities/UpdateCurrentIdentity`;

        return this.http.post<UserIdentity>(requestUrl, updateValues);
    }

    //#endregion Identity

    public getTimeZones(): Observable<any> {
        const url = this.config.backendPortalUri + `WebAPI/api/SelectablePropertyValues/GetSelectableValuesForTimeZone`;

        return this.http.get(url);
    }

    public getUserGroups(): Observable<any> {
        const url = this.config.backendPortalUri + 'WebAPI/api/SelectablePropertyValues/GetUserGroups';

        return this.http.get(url);
    }


    //#region Signature

    public getSignature(): Observable<Blob> {
        const url = this.config.backendPortalUri + 'Settings.WebAPI/api/Settings/GetUserSignature';
        return this.http.get(url, { responseType: 'blob' });
    }

    public saveSignature(signature): Observable<any> {
        const url = this.config.backendPortalUri + 'Settings.WebAPI/api/Settings/SetUserSignature';
        let formData = new FormData();
        formData.append('file[]', signature.imageBlob, signature.name);
        return this.http.post(url, formData);
    }

    public deleteSignature(): Observable<any> {
        const url = this.config.backendPortalUri + 'Settings.WebAPI/api/Settings/DeleteUserSignature';
        return this.http.delete(url);
    }

    //#endregion Signature
}
