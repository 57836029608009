<!-- Placeholder skeleton -->
<div *ngIf="loading"
    class="placeholder-container">
    <hpo-placeholder type="row"></hpo-placeholder>
    <hpo-placeholder type="row"></hpo-placeholder>
</div>

<!-- Actual content -->
<div *ngIf="!loading"
    class="section-container">

    <div>
        <h6>{{ 'portal.userInvitations.ownInvitations' | blah }}</h6>

        <div class="note">
            {{ 'portal.userInvitations.note' | blah }}
        </div>

        <ng-container *ngIf="!mobile">

            <table mat-table
                id="invitations-table"
                [dataSource]="userInvitations"
                class="invitations-table">

                <ng-container matColumnDef="inviterCompanyName">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="inviterCompanyName">
                        {{ 'default.inviterCompanyName' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let item">
                        {{ item.InviterCompanyName || '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="inviterUserName">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="inviterUserName">
                        {{ 'default.inviterUserName' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let item">
                        {{ item.InviterUserName || '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="invitationCreated">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="invitationCreated">
                        {{ 'default.invitationCreated' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let item">
                        {{ item.Created | date:'dd.MM.yyyy HH:mm' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="invitationAccepted">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="invitationAccepted">
                        {{ 'default.invitationAccepted' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let item">
                        {{ (item.Status === 'Accepted' ? (item.Modified | date:'dd.MM.yyyy HH:mm') : '-') }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="actions">
                    </th>
                    <td mat-cell
                        *matCellDef="let item">
                        @if (item.Status !== 'Accepted') {
                        <div class="action-container">
                            <button mat-button
                                mat-raised-button
                                color="accent"
                                id="invitation-{{item.Id}}-accept-button"
                                [disabled]="partialLoading"
                                matTooltipPosition="left"
                                (click)="acceptInvitation(item.Id)">
                                {{ 'default.accept' | blah }}
                            </button>
                            <button mat-button
                                mat-stroked-button
                                id="invitation-{{item.Id}}-reject-button"
                                [disabled]="partialLoading"
                                (click)="promptRejectinvitation(item.Id)">
                                {{ 'default.reject' | blah }}
                            </button>
                        </div>
                        }
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns">
                </tr>

                <tr mat-row
                    *matRowDef="let item; columns: displayedColumns"
                    id="invitation-{{item.Id}}"
                    class="rows">
                </tr>

                <tr *matNoDataRow
                    class="mat-row no-rows">
                    <td class="mat-cell"
                        [attr.colspan]="displayedColumns.length">
                        {{ 'default.noResults' | blah }}
                    </td>
                </tr>

            </table>

        </ng-container>

        <ng-container *ngIf="mobile">

            <hpo-list *ngIf="userInvitations?.length > 0; else mobileNoStatuses"
                id="invitations-mobile-list">

                <hpo-list-item *ngFor="let item of userInvitations"
                    id="invitation-mobile-{{item.Id}}">

                    <div class="mobile-list-item">

                        <div class="invitation-details">
                            <div class="company">
                                {{item.InviterCompanyName}}
                            </div>
                            <div class="info-date">

                                <span>
                                    {{ 'default.invitationCreated' | blah }}: {{ item.Created | date:'dd.MM.yyyy
                                    HH:mm' }}

                                </span>
                            </div>
                            <div class="info-date">

                                <span>
                                    {{ 'default.invitationAccepted' | blah }}: {{ (item.Status === 'Accepted' ? (item.Modified | date:'dd.MM.yyyy
                                    HH:mm') : '-') }}
                                </span>
                            </div>
                        </div>

                        <div class="actions">
                            @if (item.Status !== 'Accepted') {
                            <mat-menu #mobileActionsMenu="matMenu">
                                <button mat-menu-item
                                    id="saa-mobile-{{item.Id}}-edit-button"
                                    (click)="acceptInvitation(item.Id)">
                                    {{ 'default.accept' | blah }}
                                </button>
                                <button mat-menu-item
                                    id="saa-mobile-{{item.Id}}-delete-button"
                                    (click)="rejectInvitation(item.Id)">
                                    {{ 'default.reject' | blah }}
                                </button>
                            </mat-menu>
                            <button mat-icon-button
                                id="saa-mobile-{{item.Id}}-menu-button"
                                [disabled]="partialLoading"
                                [matMenuTriggerFor]="mobileActionsMenu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            }
                        </div>

                    </div>

                </hpo-list-item>

            </hpo-list>

        </ng-container>

    </div>

</div>




<ng-template #mobileNoStatuses>
    <p>{{ 'default.noResults' | blah }}</p>
</ng-template>