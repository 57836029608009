import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlahService } from '@headpower/blah-ng';
import { ToasterService } from '@headpower/layout';
import { catchError, Observable, Subject, takeUntil, throwError } from 'rxjs';

import { PersonStatus, PersonStatusCreate, PersonStatusPatch, PersonDetailsShort } from '../person-status.model';
import { PersonStatusService } from '../person-status.service';

type DeputyDateType = 'start' | 'end';

@Component({
    selector: 'app-profile-person-status-dialog',
    templateUrl: './person-status-dialog.component.html',
    styleUrls: ['./person-status-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonStatusDialogComponent implements OnInit, OnDestroy {

    public submitting: boolean = false;
    public isDirty: boolean = false;
    public mobile!: boolean;
    public creatingNew!: boolean;
    public companyPersons!: PersonDetailsShort[];

    public deputyPerson: PersonDetailsShort | null = null;
    public description: string | null = null;
    public showAsAbsent: boolean = false;

    public get startDate() { return this._startDate };
    public set startDate(value: string | null) { this._startDate = value?.split('T')[0] ?? null; }
    private _startDate: string | null = null;

    public get endDate() { return this._endDate; }
    public set endDate(value: string | null) { this._endDate = value?.split('T')[0] ?? null; }
    private _endDate: string | null = null;

    public originalPersonStatus?: PersonStatus;

    private destroy$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: any,
        private dialogRef: MatDialogRef<PersonStatusDialogComponent>,
        private personStatusService: PersonStatusService,
        private blahService: BlahService,
        private toaster: ToasterService,
        private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.mobile = this.dialogData.mobile ?? false;
        this.creatingNew = this.dialogData.creatingNew ?? false;
        this.companyPersons = this.dialogData.companyPersons ?? [];

        if (!this.creatingNew) {
            this.originalPersonStatus = this.dialogData.personStatus;

            this.deputyPerson = this.originalPersonStatus.DeputyPersonId ?
                this.companyPersons.find(o => o.Id === this.originalPersonStatus.DeputyPersonId) :
                null;
            this.startDate = this.originalPersonStatus.StartDate;
            this.endDate = this.originalPersonStatus.EndDate;
            this.description = this.originalPersonStatus.Description;
            this.showAsAbsent = this.originalPersonStatus.IsAppointerAbsent;
        } else {
            // When creating new person status set form as dirty
            this.isDirty = true;
        }

        if (this.mobile) {
            (this.dialogData.actionClicked as Observable<void>)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.submit();
                })
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public setDirty() {
        this.isDirty = true;
    }

    public dateChange(type: DeputyDateType) {
        const value = type === 'start' ? this.startDate : this.endDate;

        if (value) {
            if (type === 'start' && this.endDate && this.endDate < value) {
                this.endDate = value;
            }
            else if (type === 'end' && this.startDate && this.startDate > value) {
                this.startDate = value;
            }
        }
    }

    public submit() {
        if (this.submitting) {
            return;
        }

        if (!this.validateStatus()) {
            this.toaster.create(this.blahService.blah('default.formContainsErrors'), {
                color: 'warn'
            });
            return;
        }

        this.submitting = true;
        this.cdRef.markForCheck();

        if (this.creatingNew) {
            this.submitCreate();
        } else {
            this.submitPatch();
        }
    }

    private validateStatus() {
        return !!(this.startDate && this.endDate && this.startDate <= this.endDate);
    }

    private submitCreate() {
        const values: PersonStatusCreate = {
            DeputyPersonId: this.deputyPerson?.Id ?? null,
            StartDate: this.startDate,
            EndDate: this.endDate,
            Description: this.description || null,
            IsAppointerAbsent: this.showAsAbsent
        };

        this.personStatusService.createStatus(values)
            .pipe(
                catchError(error => {
                    this.submitting = false;
                    this.cdRef.markForCheck();

                    return throwError(() => error);
                })
            )
            .subscribe(result => {
                this.dialogRef.close(true);
            });
    }

    private submitPatch() {
        let values: PersonStatusPatch = {};

        const deputyPersonId = this.deputyPerson?.Id ?? null;
        const description = this.description || null;

        if (deputyPersonId !== this.originalPersonStatus.DeputyPersonId) {
            values.DeputyPersonId = deputyPersonId;
        }
        if (this.startDate !== this.originalPersonStatus.StartDate) {
            values.StartDate = this.startDate;
        }
        if (this.endDate !== this.originalPersonStatus.EndDate) {
            values.EndDate = this.endDate;
        }
        if (description !== this.originalPersonStatus.Description) {
            values.Description = description;
        }
        if (this.showAsAbsent !== this.originalPersonStatus.IsAppointerAbsent) {
            values.IsAppointerAbsent = this.showAsAbsent;
        }

        // If no changes have been made just close the dialog
        if (Object.keys(values).length === 0) {
            this.dialogRef.close();
            return;
        }

        const personStatusId = this.originalPersonStatus.Id;

        this.personStatusService.patchStatus(personStatusId, values)
            .pipe(
                catchError(error => {
                    this.submitting = false;
                    this.cdRef.markForCheck();

                    return throwError(() => error);
                })
            )
            .subscribe(result => {
                this.dialogRef.close(true);
            });
    }
}
