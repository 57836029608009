<div class="placeholder-container" *ngIf="loading">
    <hpo-placeholder *ngFor="let placehoder of ' '.repeat(10).split(''), let x = index" [width]="'100%'" [type]="'row'"></hpo-placeholder>
</div>
<ng-container *ngIf="!loading">
    <div *ngIf="showProducts && products" class="product-list">
        <div *ngFor="let product of products">
            <mat-card appearance="outlined" class="mat-card-product">
                <a target="{{openProductToNewTab ? '_blank' : undefined}}" mat-list-item
                   [href]="product.url">
                    <mat-card-content class="product-container">
                        <div class="product-image">
                            <img class="image-product" src="{{product.productIcon}}" />
                        </div>
                        <div class="favorite-icon">
                            <mat-icon class="favorite-color"
                                [ngClass]="{'material-symbols-filled': isFavorite(product)}"
                                (click)="toggleAddFavorites(product); false">
                                star
                            </mat-icon>
                        </div>
                        <div class="product-name">
                            <div class="product-text">
                                {{product.name}}
                            </div>
                            <div class="product-path">
                                {{product.accessGroupName}}
                            </div>
                        </div>
                    </mat-card-content>
                </a>
            </mat-card>
        </div>
    </div>

    <div *ngIf="!showProducts && links" class="link-list">
        <div *ngFor="let link of links">
            <a target="{{openProductToNewTab ? '_blank' : undefined}}" mat-list-item
               [href]="link.url">{{link.name}}</a>
        </div>
    </div>
    <mat-list class="no-products-note"
              *ngIf="products.length == 0">
        <mat-list-item>{{ 'portal.noProducts' | blah }}</mat-list-item>
    </mat-list>

</ng-container>
