import { SafeHtml } from '@angular/platform-browser';

export class InfoItem {
    public header: string;
    public body: string | SafeHtml;

    constructor() {
        this.header = '';
        this.body = '';
    }
}
