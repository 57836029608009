.notification-list-page-container {
    padding: 20px 10px 10px 10px;
}

.notification {
    &-header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 16px;
        min-height: 46px;

        &-title-container {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            align-items: center;
        }

        &-title {
            font-weight: 700;
            flex: 1;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &-back-btn {
            margin-bottom: 4px;
        }
    }

    &-body {
        img {
            max-width: 100% !important;
            height: auto;
        }
    }
}

@media screen and (min-width: 1040px) {
    .notification-list-page-container {
        margin: 0 auto;
        width: 800px;
        padding-top: 50px;
    }
}

@media screen and (max-width: 495px) {
    .notification {
        &-header {
            margin-right: 0;

            &-title-container {
                display: block;
            }

            &-title {
                width: 100%;
            }
        }
    }
}