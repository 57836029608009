<!-- Placeholder skeleton -->
<div *ngIf="loading"
    class="placeholder-container">
    <hpo-placeholder type="input"
        [rows]="5"></hpo-placeholder>
    <hpo-placeholder type="input"
        [rows]="5"></hpo-placeholder>
</div>

<!-- Actual content -->
<div *ngIf="!loading"
    class="section-container">

    <!-- As appointer -->
    <div>
        <h6>{{ 'portal.personStatus.ownDeputiesAndStatuses' | blah }}</h6>

        <div class="note">
            {{ 'portal.personStatus.delayNote' | blah:['15'] }}
        </div>

        <div class="actions-container">
            <button mat-stroked-button
                id="add-person-status-button"
                color="accent"
                [disabled]="partialLoading"
                (click)="createStatus()">
                <mat-icon>add</mat-icon>
                {{ 'portal.personStatus.addDeputyOrStatus' | blah | uppercase }}
            </button>
        </div>

        <ng-container *ngIf="!mobile">

            <table mat-table
                id="statuses-as-appointer-table"
                [dataSource]="statusesAsAppointer"
                class="statuses-table">

                <ng-container matColumnDef="person">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="person">
                        {{ 'default.deputy' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.DeputyPersonName || '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="start-date">
                        {{ 'default.starts' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.StartDate | date:'shortDate' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="end-date">
                        {{ 'default.ends' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.EndDate | date:'shortDate' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="description">
                        {{ 'default.description' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.Description || '-' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="absent">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="absent center">
                        {{ 'portal.personStatus.showAsAbsent' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus"
                        class="center">
                        <mat-icon *ngIf="personStatus.IsAppointerAbsent">check</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="actions">
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        <button mat-icon-button
                            id="saa-{{personStatus.Id}}-edit-button"
                            [disabled]="partialLoading"
                            matTooltip="{{ 'default.edit' | blah }}"
                            matTooltipPosition="left"
                            (click)="editStatus(personStatus)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button
                            id="saa-{{personStatus.Id}}-delete-button"
                            [disabled]="partialLoading"
                            matTooltip="{{ 'default.delete' | blah }}"
                            matTooltipPosition="right"
                            (click)="deleteStatus(personStatus.Id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="asAppointerDisplayedColumns">
                </tr>

                <tr mat-row
                    *matRowDef="let personStatus; columns: asAppointerDisplayedColumns"
                    id="saa-{{personStatus.Id}}"
                    class="rows">
                </tr>

                <tr *matNoDataRow
                    class="mat-row no-rows">
                    <td class="mat-cell"
                        [attr.colspan]="asAppointerDisplayedColumns.length">
                        {{ 'default.noResults' | blah }}
                    </td>
                </tr>

            </table>

        </ng-container>

        <ng-container *ngIf="mobile">

            <hpo-list *ngIf="statusesAsAppointer?.length > 0; else mobileNoStatuses"
                id="statuses-as-appointer-mobile-list">

                <hpo-list-item *ngFor="let personStatus of statusesAsAppointer"
                    id="saa-mobile-{{personStatus.Id}}">

                    <div class="mobile-list-item">

                        <div class="status-details">
                            <div *ngIf="personStatus.DeputyPersonName"
                                class="person-name">
                                {{personStatus.DeputyPersonName}}
                            </div>
                            <div class="info-date">
                                <mat-icon *ngIf="personStatus.Description"
                                    color="primary"
                                    #descMobileTooltip="matTooltip"
                                    [matTooltip]="personStatus.Description"
                                    matTooltipClass="hpo"
                                    (click)="descMobileTooltip.toggle()">
                                    info
                                </mat-icon>
                                <span>
                                    {{ personStatus.StartDate | date:'shortDate' }}
                                    -
                                    {{ personStatus.EndDate | date:'shortDate' }}
                                </span>
                            </div>
                            <div class="absent">
                                <mat-icon *ngIf="personStatus.IsAppointerAbsent">check</mat-icon>
                                <mat-icon *ngIf="!personStatus.IsAppointerAbsent"
                                    class="insignificant">remove</mat-icon>

                                <span>{{ 'portal.personStatus.showAsAbsent' | blah }}</span>
                            </div>
                        </div>

                        <div class="actions">
                            <mat-menu #mobileActionsMenu="matMenu">
                                <button mat-menu-item
                                    id="saa-mobile-{{personStatus.Id}}-edit-button"
                                    (click)="editStatus(personStatus)">
                                    {{ 'default.edit' | blah }}
                                </button>
                                <button mat-menu-item
                                    id="saa-mobile-{{personStatus.Id}}-delete-button"
                                    (click)="deleteStatus(personStatus.Id)">
                                    {{ 'default.delete' | blah }}
                                </button>
                            </mat-menu>
                            <button mat-icon-button
                                id="saa-mobile-{{personStatus.Id}}-menu-button"
                                [disabled]="partialLoading"
                                [matMenuTriggerFor]="mobileActionsMenu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>

                    </div>

                </hpo-list-item>

            </hpo-list>

        </ng-container>

    </div>

    <!-- As deputy -->
    <div>

        <h6>{{ 'portal.personStatus.asDeputy' | blah }}</h6>

        <ng-container *ngIf="!mobile">

            <table mat-table
                id="statuses-as-deputy-table"
                [dataSource]="statusesAsDeputy"
                class="statuses-table">

                <ng-container matColumnDef="person">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="person">
                        {{ 'default.person' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{personStatus.AppointerPersonName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="start-date">
                        {{ 'default.starts' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.StartDate | date:'shortDate' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="end-date">
                        {{ 'default.ends' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.EndDate | date:'shortDate' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell
                        *matHeaderCellDef
                        class="description">
                        {{ 'default.description' | blah }}
                    </th>
                    <td mat-cell
                        *matCellDef="let personStatus">
                        {{ personStatus.Description || '-' }}
                    </td>
                </ng-container>

                <ng-container *ngFor="let column of asDeputySpacerColumns"
                    [matColumnDef]="column.columnDef">
                    <th mat-header-cell
                        *matHeaderCellDef
                        [ngClass]="column.className">
                    <td mat-cell
                        *matCellDef>
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="asDeputyDisplayedColumns">
                </tr>

                <tr mat-row
                    *matRowDef="let personStatus; columns: asDeputyDisplayedColumns"
                    id="sad-{{personStatus.Id}}"
                    class="rows">
                </tr>

                <tr *matNoDataRow
                    class="mat-row no-rows">
                    <td class="mat-cell"
                        [attr.colspan]="asDeputyDisplayedColumns.length">
                        {{ 'default.noResults' | blah }}
                    </td>
                </tr>

            </table>

        </ng-container>

        <ng-container *ngIf="mobile">

            <hpo-list *ngIf="statusesAsDeputy?.length > 0; else mobileNoStatuses"
                id="statuses-as-deputy-mobile-list">

                <hpo-list-item *ngFor="let personStatus of statusesAsDeputy"
                    id="sad-mobile-{{personStatus.Id}}">

                    <div class="mobile-list-item">

                        <div class="status-details">
                            <div class="person-name">
                                {{personStatus.AppointerPersonName}}
                            </div>
                            <div class="info-date">
                                <mat-icon *ngIf="personStatus.Description"
                                    color="primary"
                                    #descMobileTooltip="matTooltip"
                                    [matTooltip]="personStatus.Description"
                                    matTooltipClass="hpo"
                                    (click)="descMobileTooltip.toggle()">
                                    info
                                </mat-icon>
                                <span>
                                    {{ personStatus.StartDate | date:'shortDate' }}
                                    -
                                    {{ personStatus.EndDate | date:'shortDate' }}
                                </span>
                            </div>
                        </div>

                    </div>

                </hpo-list-item>

            </hpo-list>

        </ng-container>

    </div>

</div>




<ng-template #mobileNoStatuses>
    <p>{{ 'default.noResults' | blah }}</p>
</ng-template>
