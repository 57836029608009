import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlahService } from '@headpower/blah-ng';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '@headpower/layout';
import { Subject, takeUntil } from 'rxjs';

import { AppConfig } from '../app.config';

@Component({
    selector: 'app-product-redirect',
    templateUrl: './product-redirect.component.html',
    styleUrls: ['./product-redirect.component.scss']
})
export class ProductRedirectComponent implements OnInit, OnDestroy {

    private id: number;

    private destroy$: Subject<void> = new Subject();

    constructor(private sharedService: SharedService,
        private config: AppConfig,
        private blahService: BlahService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                this.id = +params['id'];
            });

        this.sharedService.getApplicationData(this.blahService.currentLocale)
            .subscribe(data => {
                const element = data.find(o => o.Access_UID === this.id);

                if (element) {
                    window.location.replace(this.buildUrl(element.Url));
                } else {
                    this.router.navigate(['/NotFound'], { skipLocationChange: true });
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private buildUrl(url: string) {
        // Ensures that url starts with http or https
        if (!url.startsWith('http')) {
            url = this.config.portalBaseUri.replace(/\/$/, '') + url;
        }
        return url;
    }
}
