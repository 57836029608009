import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutBreakpointService } from '@headpower/layout';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

    handsetLayout: boolean;
    handsetView: number = 0;

    private destroy$: Subject<void> = new Subject();

    constructor(private layoutBreakpoint: LayoutBreakpointService) { }

    ngOnInit() {
        this.layoutBreakpoint.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.handsetLayout = result.handset);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openHandsetView(view: number) {
        this.handsetView = view;
    }
}
