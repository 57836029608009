<h2 *ngIf="!mobile"
    mat-dialog-title>
    {{ (creatingNew ? 'portal.personStatus.addDeputyOrStatus' : 'portal.personStatus.editDeputyOrStatus') | blah }}
</h2>

<div class="form-container"
    [class.mobile]="mobile">

    <hpo-select id="person-status-dialog-deputy-person-select"
        style="grid-row: 1"
        title="{{ 'default.deputy' | blah }}"
        [allowEmpty]="true"
        [data]="companyPersons"
        searchBy="Name"
        [(selected)]="deputyPerson"
        (selectedChange)="setDirty()">
        <ng-template let-item>
            {{item.Name}}
        </ng-template>
    </hpo-select>

    <hpo-datetimepicker [id]="'person-status-dialog-start-date-picker'"
        style="grid-row: 1"
        [hideTimeInput]="true"
        [endOfDayTime]="true"
        [touch]="mobile"
        required
        datePlaceholder="{{ 'default.starts' | blah }}"
        [(value)]="startDate"
        (valueChange)="setDirty(); dateChange('start')">
    </hpo-datetimepicker>

    <hpo-datetimepicker [id]="'person-status-dialog-end-date-picker'"
        style="grid-row: 1"
        [hideTimeInput]="true"
        [endOfDayTime]="true"
        [touch]="mobile"
        required
        datePlaceholder="{{ 'default.ends' | blah }}"
        [(value)]="endDate"
        (valueChange)="setDirty(); dateChange('end')">
    </hpo-datetimepicker>

    <mat-form-field style="grid-row: 2; grid-column: span 3">
        <mat-label>{{ 'default.description' | blah }}</mat-label>
        <input id="person-status-dialog-description-input"
            matInput
            maxlength="100"
            [(ngModel)]="description"
            (ngModelChange)="setDirty()">
    </mat-form-field>

    <mat-checkbox id="person-status-dialog-show-as-absent-checkbox"
        style="grid-row: 3"
        [(ngModel)]="showAsAbsent"
        (ngModelChange)="setDirty()">
        {{ 'portal.personStatus.showAsAbsent' | blah }}
    </mat-checkbox>

</div>

<mat-dialog-actions *ngIf="!mobile"
    align="end">

    <hpo-button id="person-status-dialog-cancel-button"
        [disabled]="submitting"
        mat-dialog-close>
        {{ 'default.cancel' | blah | uppercase }}
    </hpo-button>

    <hpo-button id="person-status-dialog-submit-button"
        color="accent"
        [disabled]="!isDirty"
        [loading]="submitting"
        (click)="submit()">
        {{ 'default.save' | blah | uppercase }}
    </hpo-button>

</mat-dialog-actions>