<h2 *ngIf="title"
    mat-dialog-title>
    {{title}}
</h2>

<mat-dialog-content>
    {{message}}
</mat-dialog-content>

<mat-dialog-actions align="end">

    <button mat-button
        id="confirm-dialog-cancel-button"
        [mat-dialog-close]="false">
        {{ cancelText | uppercase }}
    </button>

    <button mat-button
        id="confirm-dialog-confirm-button"
        color="accent"
        cdkFocusInitial
        [mat-dialog-close]="true">
        {{ confirmText | uppercase }}
    </button>

</mat-dialog-actions>