<div class="title-container">
    <div class="notification-title">
        {{notification.title}}
    </div>

    <div class="notification-date">
        {{ notification.startDate | date:'shortDate' }}
    </div>

    <mat-icon class="copy-link"
        matTooltip="{{ 'portal.copyLink' | blah }}"
        (click)="copyLink()">
        link
    </mat-icon>
</div>
<span class="notification-product-name">{{notification.productName}}</span>
<p class="content-container"
    [innerHtml]="notification.content"></p>