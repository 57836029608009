import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';
import { finalize, Subject } from 'rxjs';

import { UserInvitation, UserInvitationResult } from './user-invitation.model';
import { UserInvitationsService } from './user-invitations.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';



@Component({
    selector: 'app-profile-user-invitations',
    templateUrl: './user-invitations.component.html',
    styleUrls: ['./user-invitations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInvitationsComponent implements OnInit, OnDestroy {

    @Input() public mobile: boolean = false;

    public loading: boolean = true;
    public partialLoading: boolean = false;
    public userInvitations?: UserInvitation[];

    public displayedColumns: string[] = [];

    private destroy$: Subject<void> = new Subject();

    constructor(
        private userInvitationsService: UserInvitationsService,
        private dialog: MatDialog,
        private blahService: BlahService,
        private toaster: ToasterService,
        private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.fetchUserInvitations();

    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private fetchUserInvitations() {
        this.userInvitationsService.getCurrentUserUserInvitations()
            .pipe(finalize(() => {
                this.loading = false;
                this.setColumns();
                this.cdRef.markForCheck();
            }))
            .subscribe({
                next: (invitations: UserInvitation[]) => {
                    this.userInvitations = invitations;
                },
                error: () => {

                }
            });
    }

    private setColumns() {
        if (this.userInvitations.some(o => o.Status !== 'Accepted')) {
            this.displayedColumns = ['inviterCompanyName', 'inviterUserName', 'invitationCreated', 'invitationAccepted', 'actions'];
        }
        else {
            this.displayedColumns = ['inviterCompanyName', 'inviterUserName', 'invitationCreated', 'invitationAccepted'];
        }
        this.cdRef.markForCheck();
    }

    public acceptInvitation(invitationId: string) {
        this.partialLoading = true;
        this.cdRef.markForCheck();
        this.userInvitationsService.acceptUserInvitation(invitationId)
            .pipe(finalize(() => {
                this.partialLoading = false;
                this.cdRef.markForCheck();
            }))
            .subscribe({
                next: (result: UserInvitationResult) => {
                    if (result.Success) {
                        this.fetchUserInvitations();
                    }
                    else {
                        this.toaster.create(this.blahService.blah('portal.userInvitations.acceptFailed'));
                    }
                },
                error: () => {
                    this.toaster.create(this.blahService.blah('portal.userInvitations.acceptFailed'));
                }
            });
    }

    public promptRejectinvitation(invitationId: string) {
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: this.blahService.blah('portal.userInvitations.rejectInvitation'),
                message: this.blahService.blah('portal.userInvitations.rejectInvitationConfirmation')
            }
        })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.rejectInvitation(invitationId);
                }
            });
    }

    private rejectInvitation(invitationId: string) {
        this.partialLoading = true;
        this.cdRef.markForCheck();
        this.userInvitationsService.rejectUserInvitation(invitationId)
            .pipe(finalize(() => {
                this.partialLoading = false;
                this.cdRef.markForCheck();
            }))
            .subscribe({
                next: (result: UserInvitationResult) => {
                    if (result.Success) {
                        this.fetchUserInvitations();
                    }
                    else {
                        this.toaster.create(this.blahService.blah('portal.userInvitations.rejectFailed'));
                    }
                },
                error: () => {
                    this.toaster.create(this.blahService.blah('portal.userInvitations.rejectFailed'));
                }
            });
    }
}
