<div class="notification-list-page-container">
    <ng-container *ngIf="loading">
        <hpo-placeholder *ngFor="let placehoder of ' '.repeat(5).split(''), let x = index"
            [width]="'100%'"
            [type]="'row'"></hpo-placeholder>
    </ng-container>
    <div id="notifications-content"
        *ngIf="!loading">
        <div *ngIf="!authorized">
            <h4 style="margin-left: 8px;">
                {{ 'portal.news.unAuthorized' | blah }}
            </h4>
            <hpo-button color="primary"
                (click)="openPortal()">
                <mat-icon color="primary">home</mat-icon>
                {{ 'default.toPortal' | blah }}
            </hpo-button>
        </div>
        <div *ngIf="!loading && authorized && allNotificationItems.length == 0">
            <h6 style="text-align: center;">
                {{ 'default.noCurrentNews' | blah }}
            </h6>
            <div style="text-align: center;">
                <hpo-button color="primary"
                    (click)="openPortal()">
                    <mat-icon color="primary">home</mat-icon>
                    {{ 'default.toPortal' | blah }}
                </hpo-button>
            </div>
        </div>
        <div *ngIf="unReadNotificationsCount"
            class="as-read-btn-container">
            <a id="markNewsAsRead-btn"
                href="#"
                (click)="markNewsAsRead(); $event.stopPropagation(); false">
                {{ 'default.markNewsAsRead' | blah }}
            </a>
        </div>
        <div class="notifications-list">
            <div *ngFor="let notification of pageItems; let i = index"
                class="list-item"
                [ngClass]="{'unread': notification.isUnchecked}"
                (click)="openNotification(notification)">
                <div class="list-item-title">
                    <mat-icon *ngIf="notification.important">priority_high</mat-icon>
                    {{ notification.title }}
                </div>
                <div class="list-item-date">
                    <ng-container *ngIf="notification.dateText">{{ 'portal.' + notification.dateText | blah }}</ng-container>
                    <ng-container *ngIf="!notification.dateText">{{ notification.startDate | date: 'shortDate' }}</ng-container>
                </div>
            </div>
            <div *ngIf="noOfPages > 1"
                class="list-pagination">
                <hpo-button [disabled]="currentPage <= 1"
                    (click)="prevPage()">
                    <mat-icon>chevron_left</mat-icon>
                    {{ 'default.previous' | blah }}
                </hpo-button>
                <hpo-button [disabled]="currentPage >= noOfPages"
                    (click)="nextPage()">
                    {{ 'default.next' | blah }}
                    <mat-icon>chevron_right</mat-icon>
                </hpo-button>
            </div>
        </div>
    </div>
</div>