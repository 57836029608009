import { Component } from '@angular/core';

@Component({
    template: `
<hpo-notification
    [displayLink]="true"
    linkText="{{ 'default.toFrontPage' | blah }}"
    linkHref="/">

    {{ 'default.pageNotFound' | blah }}
</hpo-notification>
    `,
})
export class PageNotFoundComponent { }
