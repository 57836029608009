<ng-container *ngIf="!notificationIsSelected">
    <div class="placeholder-container"
        *ngIf="loading">
        <hpo-placeholder *ngFor="let placeholder of ' '.repeat(10).split(''), let x = index"
            [width]="'100%'"
            [type]="'row'"></hpo-placeholder>
    </div>

    <ng-container *ngIf="!loading && products">
        <div style="padding:5px;">
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title class="news-filter-title">
                        {{ 'default.filterNews' | blah }} <mat-icon>filter_list</mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-chip-listbox class="mat-chip-list-stacked">
                    <mat-chip-option *ngFor="let prod of filteredProducts"
                        [selected]="prod.selected"
                        [selectable]="prod.selectable"
                        [disabled]="!prod.selectable && !prod.selected"
                        (click)="toggleSelection(prod)"
                        class="chip">
                        {{ prod.name }}
                    </mat-chip-option>
                </mat-chip-listbox>
            </mat-expansion-panel>
        </div>
        <div id="markNewsAsRead">
            <a href="#"
                (click)="markNewsAsRead(); $event.stopPropagation(); false">
                {{ 'default.markNewsAsRead' | blah }}
            </a>
        </div>
        <div class="notification-list__container">
            @for (item of notificationItems; track item) {
            <div class="notification-list-item__container"
                [ngClass]="{'isUnchecked': item.isUnchecked}"
                (click)="openNotification(item)">
                <img class="notification--icon"
                    src="{{item.productIcon}}" />
                @if (item.important) {
                <div class="notification--important">!</div>
                }
                <div class="notification__content">
                    <div class="notification--title">
                        <span [innerHtml]="item.title  | filterTags"></span>
                    </div>
                    <div class="notification--product-name">
                        {{item.productName}}
                    </div>
                </div>
                <div class="notification--date">
                    {{ item.startDate | date:'shortDate' }}
                </div>
            </div>
            }
        </div>

        <div class="list-pagination">
            <hpo-button [disabled]="currentPage <= 1"
                (click)="prev()">
                <mat-icon>chevron_left</mat-icon>
                {{ 'default.previous' | blah }}
            </hpo-button>
            <hpo-button class="pull-right"
                [disabled]="currentPage >= noOfPages"
                (click)="next()">
                {{ 'default.next' | blah }}
                <mat-icon>chevron_right</mat-icon>
            </hpo-button>
        </div>
    </ng-container>

</ng-container>

<div class="notification-container"
    *ngIf="notificationIsSelected">

    <div class="notification-header">
        <hpo-button (click)="notificationIsSelected = false">
            <mat-icon>chevron_left</mat-icon>
            {{ 'default.back' | blah }}
        </hpo-button>
    </div>

    <app-notification-item [notification]="selectedNotification">
    </app-notification-item>

</div>