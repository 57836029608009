<div class="notification-list-page-container">
    <ng-container *ngIf="loading">
        <hpo-placeholder *ngFor="let placehoder of ' '.repeat(3).split(''), let x = index"
            [width]="'100%'"
            [type]="'row'"></hpo-placeholder>
    </ng-container>

    <div *ngIf="!loading"
        class="notification-header-back-btn">
        <hpo-button (click)="exitNotification(notification.ProductId)">
            <mat-icon>chevron_left</mat-icon>
            {{ 'default.back' | blah }}
        </hpo-button>
    </div>

    <div *ngIf="notification && !loading"
        class="notification">
        <div class="notification-header">
            <div class="notification-header-title-container">
                <div class="notification-header-title">
                    <mat-icon *ngIf="notification.Important">priority_high</mat-icon>
                    {{ notification.Title }}
                </div>
                <div class="notification-header-date">
                    {{ notification.StartDate | date:'shortDate' }}
                </div>
            </div>
            <div>
                <button mat-icon-button
                    (click)="copyLink($event, notification.ObjectId)"
                    matTooltip="{{ 'portal.copyLink' | blah }}">
                    <mat-icon>link</mat-icon>
                </button>
            </div>
        </div>
        <div class="notification-content">
            <span class="notification-body"
                [innerHtml]="notification.Content"></span>
        </div>
    </div>
</div>

<hpo-notification *ngIf="!loading && !notification"
    color="warn"
    [displayLink]="true"
    linkText="{{ 'default.toFrontPage' | blah }}"
    linkHref="/">
    {{ 'default.unknownError' | blah }}
</hpo-notification>