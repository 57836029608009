import { Component, OnInit, OnDestroy, Input, Inject, ErrorHandler } from '@angular/core';
import { BlahService } from '@headpower/blah-ng';
import { SharedService } from '@headpower/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfoItem } from './info-item';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-info-item',
    templateUrl: './info-item.component.html',
    styleUrls: ['./info-item.component.css']
})
export class InfoItemComponent implements OnInit, OnDestroy {

    @Input() title: string;

    loading: boolean = false;
    infoItems: Array<InfoItem> = [];
    currentLocale: string;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private sharedService: SharedService,
        private blahService: BlahService,
        @Inject(ErrorHandler) public errorHandler: any,
        private sanitizer: DomSanitizer) { }

    ngOnInit() {

        this.blahService.localeChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.populateData());

        this.populateData();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private populateData() {

        this.loading = true;

        this.currentLocale = this.blahService.currentLocale;

        //Clears infoItems (needed in case if language is changed)
        this.infoItems = [];

        this.sharedService.getTextObject(13, 2, this.currentLocale).subscribe(
            (data) => {
                for (const text of data.TextParts) {
                    const infoItem = new InfoItem();
                    // FYI: Value comes from settings and cannot be modified by user
                    infoItem.body = this.sanitizer.bypassSecurityTrustHtml(text.Value);

                    this.infoItems.push(infoItem);
                }
                this.loading = false;
            },
            (error) => {
                if (error.status == '404') {
                    const infoItem = new InfoItem();
                    infoItem.body = 'Info item not found';

                    this.infoItems.push(infoItem);

                    this.loading = false;
                }
                else {
                    this.errorHandler.handleError(error);
                }
            });
    }
}
