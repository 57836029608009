import { Component, ViewChild, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlahService } from '@headpower/blah-ng';
import { LayoutBreakpointService, SharedService, ToasterService } from '@headpower/layout';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, NgForm } from '@angular/forms';
import { Observable, filter, forkJoin, Subject, takeUntil, concat, takeLast } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { SignatureDialogComponent } from './signature-dialog/signature-dialog.component'
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../app.config';
import { ProfileService, UserIdentity, UserInfo } from './profile-service.component';
import { FeatureFlagService } from '@headpower/featureflags';

type TabIndexType = 'InfoForm' |
    'IdentityForm' |
    'CommunicationPreferences' |
    'UserInvitations' |
    'PersonStatuses' |
    'GravatarInfo';

export class BusinessDomainConfig {
    value: string;
    translatedValue?: string;
}
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {


    private businessDomains: BusinessDomainConfig[] = [
        { value: 'None' },
        { value: 'Electricity' },
        { value: 'DistrictHeating' },
        { value: 'Telecommunications' },
        { value: 'Water' },
        { value: 'DistrictCooling' },
        { value: 'Municipality' },
    ];

    public loading: boolean = true;
    public mobile: boolean = false;
    public tabIndex: number = 0;
    public hasDeputyFeatureRight: boolean = undefined;
    public currentLocale: string;
    public claims: any;
    public userInfo: UserInfo;
    public userIdentity: UserIdentity;
    public openProductToNewTabValueToSave: string;
    private pendingTabIndexType?: TabIndexType;
    private deputyFeatureProductId!: number;
    public eulaText: string;
    public endUserAgreement: string;
    public timeZones: any;
    public altUsername: string;
    public altUsernameOriginal: string;
    public userProfileForm: UntypedFormGroup;
    public contactInfoPermit: boolean;
    public massMail: boolean;
    public defaultBusinessDomain: string;
    public submitting: boolean = false;
    public openProductToNewTab: boolean;
    public formChanged: boolean = false;
    public portalBaseUri: string;
    public accountBaseUri: string;
    public location: string;
    public dateTimeFormat: string = 'l LTS';

    public ff_newAccount = undefined;

    public signatureData = "";
    public loadingSignature: boolean = true;
    public showSignature: boolean = false;
    public saveDisabled = true;
    public maximize: boolean = false;
    public popUpSize = 2;
    public ZoomedSignature = false;

    @ViewChild('userProfileFg') public userProfileFormRef: NgForm;
    @ViewChild('Signature') kendoSignature: ElementRef;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private profileService: ProfileService,
        private config: AppConfig,
        private oAuthService: OAuthService,
        private blahService: BlahService,
        private toaster: ToasterService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private layoutBreakpointService: LayoutBreakpointService,
        private sharedService: SharedService,
        private fb: UntypedFormBuilder,
        private featureFlagService: FeatureFlagService
    ) {
    }

    ngOnInit() {
        this.featureFlagService.isFeatureAvailable("Account.NewAccount").subscribe(res => {
            this.ff_newAccount = res;

            if (this.ff_newAccount && this.pendingTabIndexType === 'UserInvitations') {
                this.tabIndex = this.getTabIndex(this.pendingTabIndexType);
                this.pendingTabIndexType = void 0;
            }
        });

        this.currentLocale = this.blahService.currentLocale;
        this.deputyFeatureProductId = this.config.deputyFeatureProductId;
        const claims = this.oAuthService.getIdentityClaims();
        this.claims = claims;
        this.accountBaseUri = this.config.accountBaseUri;

        this.blahService.localeChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.translateBusinessDomainValuesAndSort());

        this.translateBusinessDomainValuesAndSort();

        let settingRequests = [
            // Get EULA text
            this.sharedService.getTextObject(11, 1, this.currentLocale),
            // Get end user agreement text
            this.sharedService.getTextObject(12, 1, this.currentLocale),
            // Get indication where to open product (new tab or current one)
            this.sharedService.getSettingsValue(13, 1701)
        ];

        forkJoin(settingRequests)
            .subscribe({
                next: results => {
                    this.eulaText = results[0].TextParts[1].Value;
                    this.endUserAgreement = results[1].TextParts[1].Value;

                    const openProductToNewTab = results[2] === '1';

                    this.openProductToNewTab = openProductToNewTab;
                    this.openProductToNewTabValueToSave = openProductToNewTab ? '1' : '0';
                },
                error: error => {
                    this.toaster.create(this.blahService.blah('layout.unknownError'));
                }
            })

        let userRequests = [
            this.profileService.getUserInfo(),
            this.profileService.getUserIdentity(),
            this.profileService.getTimeZones()
        ];

        forkJoin(userRequests)
            .subscribe({
                next: results => {
                    this.userInfo = this.mapUserInfo(results[0]);
                    this.userIdentity = results[1];
                    this.timeZones = results[2];

                    this.userProfileForm = this.fb.group({
                        firstName: [this.userInfo.FirstName, Validators.required],
                        lastName: [this.userInfo.LastName, Validators.required],
                        position: [this.userInfo.Position],
                        phone: [this.userInfo.Phone, Validators.pattern(/^\+?\d{1,4}?[- ]?[- ]?\d{1,4}[- ]?\d{1,4}[- ]?\d{1,4}[- ]?\d{1,9}$/)],
                        language: [this.userInfo.Language],
                        timeZone: [this.userInfo.TimeZone]
                    });

                    this.altUsername = this.userIdentity.AlternativeUserName;
                    this.altUsernameOriginal = this.userIdentity.AlternativeUserName;
                    this.contactInfoPermit = this.userInfo.ContactInfoPermit as boolean;
                    this.massMail = this.userInfo.MassMail as boolean;
                    this.defaultBusinessDomain = (this.userInfo.DefaultBusinessDomain as string)

                    this.loading = false;
                },
                error: error => {
                    this.toaster.create(this.blahService.blah('layout.genericCommunicationError'));
                }
            });

        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                this.mobile = result.handset;
            })

        this.route.fragment
            .pipe(
                filter(result => !!result)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(fragment => {
                if (!this.loading) {
                    this.tabIndex = this.getTabIndex(fragment as any);
                } else {
                    this.pendingTabIndexType = fragment as any;
                }
            });


        this.getSignature();


        forkJoin([
            this.sharedService.hasProductUsageRight(this.deputyFeatureProductId, false)
        ])
            .subscribe(results => {
                this.hasDeputyFeatureRight = results[0];

                // TODO: when removing ff_newAccount, remove latter check
                if (this.pendingTabIndexType && this.pendingTabIndexType !== 'UserInvitations') {
                    this.tabIndex = this.getTabIndex(this.pendingTabIndexType);
                    this.pendingTabIndexType = void 0;
                }
                this.loading = false;
            });
    }

    private getUserId(): string {
        return this.claims['sub'];
    }

    private mapUserInfo(entity: any) {
        return {
            ...entity,
            Language: entity.Language ?? ''
        };
    }

    public getTabIndex(type: TabIndexType) {
        switch (type) {
            case 'InfoForm': return 0;
            case 'IdentityForm': return 1;
            case 'CommunicationPreferences': return 2;
            case 'UserInvitations': return 3;
            case 'PersonStatuses': return this.ff_newAccount ? 4 : 3;
            case 'GravatarInfo': return this.ff_newAccount ? 5 : 4;
        }
    }

    public changeTab(type: TabIndexType) {
        this.tabIndex = this.getTabIndex(type);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public errors(control: string): ValidationErrors {
        return this.userProfileForm.get(control).errors;
    }

    public contactInfoPermitChange(checked: boolean) {
        this.contactInfoPermit = checked;
        this.formChanged = true;
        this.submitForm();
    }

    public markMassMail(checked: boolean) {
        this.massMail = checked;
        this.formChanged = true;
        this.submitForm();
    }

    public markOpenProductToNewTab(checked: boolean) {
        this.openProductToNewTabValueToSave = checked ? '1' : '0';
        this.formChanged = true;
        this.submitForm();
    }

    public changeAltUsername(altUsername: string) {
        this.altUsername = altUsername;
        this.formChanged = true;
        this.submitForm();
    }

    public markBusinessDomain(value) {
        this.defaultBusinessDomain = value.value;
        this.formChanged = true;
        this.submitForm();
    }

    public getSignature() {
        this.profileService.getSignature().subscribe(res => {
            if (res) {
                if (res['size'] !== 0) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.signatureData = reader.result as string;
                    };
                    reader.onloadend = () => {
                        setTimeout(() => { this.showSignature = true; this.loadingSignature = false; }, 0);
                    }
                    reader.readAsDataURL(res);
                } else {
                    this.loadingSignature = false;

                }
            }
        })
    }

    public saveSignature() {
        const imageBlob = this.dataURItoBlob(this.signatureData);
        let newSignatureBlob = {
            imageBlob: imageBlob,
            name: 'signature.png'
        };
        this.profileService.saveSignature(newSignatureBlob).subscribe({
            error: (error) => {
                throw (error);
            }
        });
    }

    openSignatureDialog() {
        this.dialog.open(SignatureDialogComponent, { panelClass: 'custom-dialog-container' }
        ).afterClosed()
            .subscribe(result => {
                if (result) {
                    if (result.save) {
                        this.signatureData = result.signatureData;
                        this.showSignature = true;
                        this.saveSignature();
                    }
                }
            });
    }

    public deleteSignature() {
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'portal.personStatus.deleteSignature',
                message: 'portal.personStatus.deleteSignatureConfirmation'
            }
        })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.profileService.deleteSignature().subscribe(() => {
                        this.showSignature = false;
                        this.signatureData = '';
                    })
                }
            });
    }

    private dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        // create a view into the buffer
        var ia = new Uint8Array(ab);
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    public submitForm() {

        // A little trick to set the form to submitted state.
        // Shows remaining errors to user.
        (this.userProfileFormRef as any).submitted = true;

        // Check if form is valid and display toast if it's not
        if (this.userProfileForm.invalid) {
            this.toaster.create(this.blahService.blah('layout.formContainsErrors'));
            return;
        }
        this.submitting = true;

        const values = this.userProfileForm.value;

        // This is fine for every submit so we can initialize also null setting to false if setting is not changed from UI
        this.sharedService.setSettingsValue(13, 4, 1701, this.openProductToNewTabValueToSave)
            .subscribe({
                error: error => {
                    // Does not stop the submit process
                    this.toaster.create(this.blahService.blah('layout.unknownError'));
                }
            });

        let requests: Observable<any>[] = [];

        if (this.altUsernameOriginal !== this.altUsername) {
            requests.push(
                this.profileService.updateUserIdentity({ AlternativeUserName: this.altUsername })
            );
        } else {

            requests.push(
                this.profileService.updateUserInfo({
                    FirstName: values.firstName,
                    LastName: values.lastName,
                    Position: values.position,
                    Phone: values.phone,
                    Language: values.language,
                    TimeZone: values.timeZone,
                    ContactInfoPermit: this.contactInfoPermit,
                    MassMail: this.massMail,
                    DefaultBusinessDomain: this.defaultBusinessDomain
                })
            );
        }

        concat(...requests)
            .pipe(
                takeLast(1)
            )
            .subscribe({
                next: result => {
                    this.altUsernameOriginal = this.altUsername;
                },
                error: error => {
                    this.submitting = false;

                    let errorMessage = error.error?.Message ?? '';
                    if (errorMessage === '' && error.error === 'Phone number format is invalid.') {
                        errorMessage = 'Phone number format is invalid.';
                    }

                    switch (errorMessage) {
                        case 'default.userNameTaken':
                            this.changeTab('IdentityForm');
                            this.toaster.create(this.blahService.blah('layout.usernameTaken'));
                            break;
                        case 'default.loginNameCanNotBeEmail':
                            this.changeTab('IdentityForm');
                            this.toaster.create(this.blahService.blah('layout.userNameCanNotBeEmail'));
                            break;
                        case 'default.loginNameTooShort':
                            this.changeTab('IdentityForm');
                            this.toaster.create(this.blahService.blah('layout.usernameTooShort'));
                            break;
                        case 'Phone number format is invalid.':
                            this.toaster.create(this.blahService.blah('layout.validation.invalidPhone'));
                            break;
                        default:
                            this.toaster.create(this.blahService.blah('layout.formCannotBeSent'));
                    }
                }
            });
    }

    translateBusinessDomainValuesAndSort() {
        if (this.businessDomains) {
            this.businessDomains.forEach(o => {
                o.translatedValue = this.blahService.blah('portal.businessDomain.' + o.value);
            });
            this.businessDomains.sort((a, b) => {
                if (a.value === 'None' && b.value !== 'None') {
                    return -1;
                }
                if (a.value !== 'None' && b.value === 'None') {
                    return 1;
                }
                return a.translatedValue.localeCompare(b.translatedValue);
            });
        }
    }
}
