import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-businessdomain-icon',
  templateUrl: './business-domain-icon.component.html'
})
export class BusinessDomainIconComponent implements OnChanges {
  @Input() businessdomain: string;
  @Input() size: string;

  public title: string = '';

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.title = 'portal.businessDomain.' + this.businessdomain;
  }

}
