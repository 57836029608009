import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm, ValidationErrors, UntypedFormControl } from '@angular/forms';
import { LayoutBreakpointService } from '@headpower/layout';
import { finalize, Subject, takeUntil } from 'rxjs';
import { UserInvitationService, UserInvitationDto } from './user-invitation.service';

@Component({
    selector: 'app-invite-person-page',
    templateUrl: './user-invitation-page.component.html',
    styleUrls: ['./user-invitation-page.component.css']
})
export class UserInvitationPageComponent implements OnInit, OnDestroy {

    @ViewChild('invitationFg') invitationFormRef: NgForm;

    handsetLayout: boolean;
    invitationForm: UntypedFormGroup;
    submitted: boolean = false;
    submitting: boolean = false;
    invitationNotSentMessage: { success: boolean, message: string, type: string } = { success: true, message: '', type: 'default' };

    private readonly destroy$: Subject<void> = new Subject();

    constructor(private readonly userInvitationService: UserInvitationService,
        private readonly layoutBreakpoint: LayoutBreakpointService,
        private readonly fb: UntypedFormBuilder) { }

    ngOnInit() {
        this.layoutBreakpoint.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.handsetLayout = result.handset)

        this.invitationForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            firstname: ['', [Validators.required, this.noWhitespaceValidator]],
            lastname: ['', [Validators.required, this.noWhitespaceValidator]]
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        return (control.value || '').trim().length ? null : { 'whitespace': true };
    }

    /**
     * Sets related properties to indicating submitted
     */
    private setSubmitted() {
        this.submitted = true;
        this.submitting = false;
    }

    errors(control: string): ValidationErrors {
        return this.invitationForm.get(control).errors;
    }

    onSubmit() {
        // Submit only if form is valid
        if (this.invitationForm.invalid) return;

        this.submitting = true;

        this.invitationNotSentMessage.success = true;
        this.invitationNotSentMessage.message = '';
        this.invitationNotSentMessage.type = 'default';

        let userInvitationDto: UserInvitationDto = {
            Email: this.invitationForm.value.email as string,
            FirstName: this.invitationForm.value.firstname as string,
            LastName: this.invitationForm.value.lastname as string
        };

        this.userInvitationService.createUserInvitation(userInvitationDto)
            .pipe(finalize(() => {
                this.setSubmitted();
            }))
            .subscribe({
                next: (result) => {
                    if (!result.Success) {
                        this.invitationNotSentMessage.success = false;
                        if (result.Message === 'ExistingUser') {
                            this.invitationNotSentMessage.message = 'portal.userInvitation.existingUser';
                        }
                        else if (result.Message === 'ArchivedIdentity') {
                            this.invitationNotSentMessage.message = 'portal.userInvitation.archivedIdentity';
                        }
                        else if (result.Message === 'RegistrationRequired') {
                            this.invitationNotSentMessage.message = 'portal.userInvitation.registrationRequired';
                        }
                        else if (result.Message === 'ExistingInvitation') {
                            this.invitationNotSentMessage.message = 'portal.userInvitation.existingInvitation';
                        }
                        else if (result.Message === 'InvalidEmail') {
                            this.invitationNotSentMessage.message = 'portal.userInvitation.invalidEmail';
                        }
                        else {
                            this.invitationNotSentMessage.message = 'default.unknownError';
                            this.invitationNotSentMessage.type = 'error';
                        }
                    }
                },
                error: () => {
                    this.invitationNotSentMessage.success = false;
                    this.invitationNotSentMessage.message = 'default.unknownError';
                    this.invitationNotSentMessage.type = 'error';
                }
            })
    }

    resetInvitationForm() {
        this.submitted = false;
        this.submitting = false;

        this.invitationNotSentMessage.success = true;
        this.invitationNotSentMessage.message = '';
        this.invitationNotSentMessage.type = 'default';

        // NgForm reference is used to reset the form. (https://github.com/angular/material2/issues/4190)
        this.invitationFormRef.resetForm({
            email: '',
            firstname: '',
            lastname: ''
        });
    }
}
