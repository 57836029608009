<hpo-notification *ngIf="submitted"
    [displayLink]="true"
    linkText="{{ 'default.toFrontPage' | blah }}"
    linkHref="/">
    {{ (supportRequest ? 'default.supportRequestSent' : 'default.feedbackSent') | blah }}
</hpo-notification>

<div class="helpdesk-container content-padding desktop"
    *ngIf="!submitted">
    <hpo-card [mobile]="handsetLayout">
        <hpo-card-header color="primary">
            {{ (supportRequest ? 'default.supportRequest' : 'default.feedback') | blah }}
        </hpo-card-header>

        <hpo-card-content>

            <div class="subheading-text"
                *ngIf="supportRequest"
                [innerHTML]="this.contactInformationText">
            </div>

            <p class="subheading-text"
                *ngIf="!supportRequest">
                {{ 'default.feedbackDescription' | blah }}
            </p>

            <form class="form-container"
                [formGroup]="helpdeskForm"
                (ngSubmit)="submitRequest()"
                novalidate>

                <!-- Name -->
                <div gridRow="1"
                    gridColumn="1 / 3">
                    <mat-form-field>
                        <mat-label>{{ 'default.name' | blah }}</mat-label>
                        <input matInput
                            id="HelpdeskName"
                            formControlName="name">
                        <mat-error *ngIf="errors('name')?.required">
                            {{ 'default.validation.required' | blah }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Company -->
                <div gridRow="1"
                    gridColumn="3 / 5">
                    <mat-form-field>
                        <mat-label>{{ 'default.company' | blah }}</mat-label>
                        <input matInput
                            id="HelpdeskCompany"
                            formControlName="company">
                    </mat-form-field>
                </div>

                <!-- Email -->
                <div gridRow="2"
                    gridColumn="1 / 3">
                    <mat-form-field>
                        <mat-label>{{ 'default.email' | blah }}</mat-label>
                        <input matInput
                            id="HelpdeskEmail"
                            formControlName="email">
                        <mat-error *ngIf="errors('email')?.required">
                            {{ 'default.validation.required' | blah }}
                        </mat-error>
                        <mat-error *ngIf="errors('email')?.email && !errors('email')?.required">
                            {{ 'default.validation.invalidEmail' | blah }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Phone -->
                <div gridRow="2"
                    gridColumn="3 / 5">
                    <mat-form-field>
                        <mat-label>{{ 'default.phone' | blah }}</mat-label>
                        <input matInput
                            id="HelpdeskPhone"
                            formControlName="phone">
                    </mat-form-field>
                </div>

                <!-- Subject -->
                <div gridRow="3"
                    gridColumn="1 / 5">
                    <mat-form-field>
                        <mat-label>{{ 'default.subjectQuestion' | blah }}</mat-label>
                        <input matInput
                            id="HelpdeskSubject"
                            formControlName="subject">
                        <mat-error *ngIf="errors('subject')?.required">
                            {{ 'default.validation.required' | blah }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Description -->
                <div gridRow="4"
                    gridColumn="1 / 5">
                    <mat-form-field>
                        <mat-label>{{ 'default.description' | blah }}</mat-label>
                        <textarea matInput
                            matTextareaAutosize
                            id="HelpdeskDescription"
                            formControlName="description"></textarea>
                        <mat-error *ngIf="errors('description')?.required">
                            {{ 'default.validation.required' | blah }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Send copy -->
                <div gridRow="5"
                    gridColumn="1 / 5">
                    <mat-checkbox id="HelpdeskSendCopy"
                        formControlName="sendCopy">

                        {{ 'default.sendMeCopy' | blah }}
                    </mat-checkbox>
                </div>

                <div gridRow="6"
                    gridColumn="4"
                    class="action-container">
                    <hpo-button type="raised"
                        color="primary"
                        [loading]="submitting">

                        {{ 'default.send' | blah }}
                    </hpo-button>
                </div>

            </form>

        </hpo-card-content>
    </hpo-card>
</div>