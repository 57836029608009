import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlahModule } from '@headpower/blah-ng';
import { HpoLayoutModule } from '@headpower/layout';
import { ButtonModule, CardModule, NotificationModule, PlaceholderModule } from '@headpower/components';

/* Material components */
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationPageComponent } from './notification-page/notification-page.component';
import { ImportantNewsDialogComponent } from './important-notification/notification-important-news-dialog.component';
import { NotificationListPageComponent } from './notification-list-page/notification-list-page.component';

@NgModule({
    imports: [
        CommonModule,
        BlahModule,
        HpoLayoutModule,
        PlaceholderModule,
        ButtonModule,
        CardModule,
        NotificationModule,
        SharedModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        MatExpansionModule,
        MatCardModule,
        MatChipsModule
    ],
    declarations: [
        NotificationsListComponent,
        NotificationItemComponent,
        NotificationPageComponent,
        NotificationListPageComponent,
        ImportantNewsDialogComponent
    ],
    exports: [
        NotificationsListComponent,
        NotificationItemComponent,
        NotificationPageComponent,
        NotificationListPageComponent,
        ImportantNewsDialogComponent
    ]
})
export class NotificationsModule { }
