import { Injectable } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private oAuthService: OAuthService) { }

    /**
     * Checks if user has a given role claim.
     * 
     * @param role Role claim to check
     * @returns A boolean indicating if user has a given role claim
     */
    public userHasRoleClaim(role: string) {
        const roles = this.oAuthService.getIdentityClaims()?.['role'];

        if (!roles) {
            return false;
        }

        return Array.isArray(roles) ?
            roles.includes(role) :
            roles === role;
    }
}
