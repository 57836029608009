import { Component, OnInit, Inject, ErrorHandler, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OldPortalService } from '../../services/old-portal.service';
import { SharedService, ToasterService } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';

@Component({
    selector: 'app-notification-page',
    templateUrl: './notification-page.component.html',
    styleUrls: ['./notification-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationPageComponent implements OnInit {

    loading: boolean = true;
    notification: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        @Inject(ErrorHandler) public errorHandler: any,
        private portalService: OldPortalService,
        private sharedService: SharedService,
        private toaster: ToasterService,
        private blahService: BlahService,
        private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.portalService.getSpecificNews(params.get('id')).subscribe(
                data => {

                    this.sharedService.hasProductUsageRight(data.ProductId).subscribe(
                        data => {

                            if (data === false) {
                                this.router.navigate(['/AccessDenied']);
                            }
                        }, error => {
                            this.loading = false;
                            this.errorHandler.handleError(error);
                        });

                    this.portalService.markNotificationAsRead(data.ObjectId).subscribe(data => {
                    }, error => {
                        console.error('Failed to mark notification as read (probably was marked already)');
                    });

                    // FYI: Content cannot be modified by user
                    data.Content = this.sanitizer.bypassSecurityTrustHtml(data.Content);

                    this.notification = data;

                    this.loading = false;
                }, error => {
                    this.loading = false
                    this.errorHandler.handleError(error);
                });
        });
    }

    /**
    * Back functionality for notification
    * @param productId product id
    */
    exitNotification(productId: string) {
        this.router.navigate(['/NotificationListPage/' + productId]);
    }

    /**
    * Copies notification link
    * @param e Event
    * @param notificationId Notification id
    */
    copyLink(e: Event, notificationId: string) {
        e.stopPropagation();
        const notificationLink = `${document.baseURI}/Notification/${notificationId}`;

        navigator.clipboard.writeText(notificationLink);

        this.toaster.create(this.blahService.blah('portal.linkCopied'));
    }
}
