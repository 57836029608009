import { Component, OnInit, Input } from '@angular/core';
import { ToasterService } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';

import { Notification } from '../notification';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {

    @Input() notification: Notification;

    constructor(
        private toaster: ToasterService,
        private blahService: BlahService) { }

    ngOnInit() {
    }

    copyLink() {
        const notificationLink = `${document.baseURI}Notification/${this.notification.objectId}`;

        navigator.clipboard.writeText(notificationLink);

        this.toaster.create(this.blahService.blah('portal.linkCopied'));
    }
}
