import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export interface IConfig {
    [key: string]: any;
    environmentName: string;
    deputyFeatureProductId: number;
    clientSecret: string;
    applicationInsightsInstrumentationKey: string;
    backendAccountUri: string;
    backendPortalUri: string;
    accountBaseUri: string;
    portalBaseUri: string;
    productApiKey: string;
}

@Injectable({
    providedIn: 'root'
})
export class AppConfig {

    private config: IConfig;

    constructor(private http: HttpClient) {
    }

    get environmentName(): string {
        return this.getConfig('environmentName');
    }
    get deputyFeatureProductId(): number {
        return Number(this.getConfig('deputyFeatureProductId'));
    }
    get clientSecret(): string {
        return this.getConfig('clientSecret');
    }
    get applicationInsightsInstrumentationKey(): string {
        return this.getConfig('applicationInsightsInstrumentationKey');
    }
    get backendAccountUri(): string {
        return this.getConfig('backendAccountUri');
    }
    get backendPortalUri(): string {
        return this.getConfig('backendPortalUri');
    }
    get accountBaseUri(): string {
        return this.getConfig('accountBaseUri');
    }
    get portalBaseUri(): string {
        return this.getConfig('portalBaseUri');
    }
    get classicBaseUri(): string {
        return this.getConfig('classicBaseUri');
    }
    get productApiKey(): string {
        return this.getConfig('productApiKey');
    }

    /**
     * Use to get the data found in the config file
     * @param key Configuration key
     * @returns Configuration value
     */
    public getConfig(key: any): string {
        return this.config[key];
    }

    /**
     * Returns config object
     * @returns Config object
     */
    public getAll(): any {
        return this.config;
    }

    /**
     * This method loads "config.json" to get all env's variables
     */
    public load(appVersion?: string): Promise<any> {
        let configUrl = 'config/config.json';

        if (appVersion) {
            configUrl += `?v=${appVersion}`;
        }

        return new Promise((resolve, reject) => {
            this.http.get<IConfig>(configUrl)
                .pipe(
                    catchError((error: any) => {
                        console.error('Error reading config/config.json configuration file');
                        resolve(error);
                        return throwError(() => error?.json()?.error || 'Server error');
                    }))
                .subscribe(result => {
                    this.config = result;
                    resolve(true);
                });
        });
    }
}
