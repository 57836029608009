import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { HpoIconModule, ButtonModule, CardModule, DatetimepickerModule, DayjsPipeModule, FullScreenDialogModule, ListModule, MatDayjsDateModule, PlaceholderModule, SelectModule } from '@headpower/components';
import { BlahModule } from '@headpower/blah-ng';
import { InputsModule, SignatureModule } from '@progress/kendo-angular-inputs';
import { SharedModule } from '../shared/shared.module';
import { ProfileComponent } from './profile.component';
import { BusinessDomainIconComponent } from './business-domain-icon.component';
import { PersonStatusesComponent } from './person-statuses/person-statuses.component';
import { PersonStatusService } from './person-statuses/person-status.service';
import { ProfileService } from './profile-service.component';
import { PersonStatusDialogComponent } from './person-statuses/person-status-dialog/person-status-dialog.component';
import { HpoLayoutModule, SharedDirectivesModule, SharedPipesModule } from '@headpower/layout';
import { SignatureDialogComponent } from './signature-dialog/signature-dialog.component';
import { UserInvitationsComponent } from './user-invitations/user-invitations.component';
import { UserInvitationsService } from './user-invitations/user-invitations.service';

@NgModule({
    declarations: [
        ProfileComponent,
        PersonStatusesComponent,
        PersonStatusDialogComponent,
        SignatureDialogComponent,
        BusinessDomainIconComponent,
        UserInvitationsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatTabsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatMenuModule,
        MatDialogModule,
        FullScreenDialogModule,
        BlahModule,
        SharedModule,
        SelectModule,
        ListModule,
        ButtonModule,
        CardModule,
        PlaceholderModule,
        DatetimepickerModule,
        SignatureModule,
        InputsModule,
        ReactiveFormsModule,
        MatDayjsDateModule,
        HpoLayoutModule,
        SharedDirectivesModule,
        SharedPipesModule,
        DayjsPipeModule,
        HpoIconModule
    ],
    providers: [
        PersonStatusService,
        UserInvitationsService,
        ProfileService
    ]
})
export class ProfileModule { }
