import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductRedirectComponent } from './product-redirect/product-redirect.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { CustomerAdminGuard } from './shared/auth/customer-admin.guard';
import { PageNotFoundComponent } from './page-not-found.component';
import { HomeComponent } from './home/home.component';
import { UserInvitationPageComponent } from './invite-person/user-invitation-page/user-invitation-page.component';
import { AccessDeniedComponent } from './access-denied.component';
import { NotificationPageComponent } from './notifications/notification-page/notification-page.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { NotificationListPageComponent } from './notifications/notification-list-page/notification-list-page.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthCallbackComponent } from './auth/callback/callback.component';
import { ProfileRedirectGuard } from './shared/auth/profile-redirect.guard';
import { InvitePersonPageWrapperComponent as UserInvitationPageWrapperComponent } from './invite-person/user-invitation-page-wrapper.component';

const appRoutes: Routes = [
    // TODO: remove wrapper component & old page after new account has been deployed to production
    { path: 'InvitePerson', component: UserInvitationPageWrapperComponent /*UserInvitationPageComponent*/, canActivate: [AuthGuard, CustomerAdminGuard, ProfileRedirectGuard] },
    { path: 'Product/:id', component: ProductRedirectComponent, canActivate: [AuthGuard, ProfileRedirectGuard] },
    { path: 'Notification/:id', component: NotificationPageComponent, canActivate: [AuthGuard, ProfileRedirectGuard] },
    { path: 'NotificationListPage/:productId', component: NotificationListPageComponent, canActivate: [AuthGuard, ProfileRedirectGuard] },
    {
        path: 'Helpdesk', canActivate: [AuthGuard, ProfileRedirectGuard], children: [
            { path: '', pathMatch: 'full', redirectTo: 'Support' }, // /Helpdesk defaults to /Helpdesk/Support
            { path: 'Support', component: HelpdeskComponent, data: { type: 'support' } },
            { path: 'Feedback', component: HelpdeskComponent, data: { type: 'feedback' } }
        ]
    },
    { path: 'Profile', component: ProfileComponent, canActivate: [AuthGuard, ProfileRedirectGuard] },
    { path: 'AccessDenied', component: AccessDeniedComponent, canActivate: [ProfileRedirectGuard] },
    { path: 'NotFound', component: PageNotFoundComponent, canActivate: [ProfileRedirectGuard] },
    { path: 'auth/callback', component: AuthCallbackComponent },

    // TODO 
    { path: 'Login', redirectTo: '', pathMatch: 'prefix' },  // NOTE: This is just a temporary routing to make things work smoothly when shifting to new login flow. Remove this when not needed anymore.
    // TODO

    { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuard, ProfileRedirectGuard] },
    { path: '**', component: PageNotFoundComponent, canActivate: [ProfileRedirectGuard] }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
