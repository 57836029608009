import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanComponentDeactivate } from '@headpower/layout';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerAdminGuard {

    constructor(
        private oAuthService: OAuthService,
        private router: Router,
        private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.oAuthService.hasValidIdToken()) {
            return false;
        }

        if (this.userService.userHasRoleClaim('CustomerAdmin')) {
            return true;
        }

        this.router.navigate(['/AccessDenied']);
        return false;
    }

    canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
