import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';

export class UserInvitationDto {
    Email: string;
    FirstName: string;
    LastName: string;
}

@Injectable()
export class UserInvitationService {

    constructor(private readonly http: HttpClient, private readonly config: AppConfig) { }

    public createUserInvitation(userInvitationDto: UserInvitationDto): Observable<any> {
        const url = `${this.config.backendPortalUri}Portal.WebAPI/UserInvitations/InviteNewUser`;
        return this.http.post(url, userInvitationDto);
    }
}
