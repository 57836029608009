import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { AppOAuthStorage } from '@headpower/angular-oauth2-oidc-extensions';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { BlahService } from '@headpower/blah-ng';
import { MenuItem, CurrentUser, ApplicationInsightsService, LayoutBreakpointService, SharedService } from '@headpower/layout';
import { forkJoin, mergeMap, map, filter } from 'rxjs';

import { environment } from '../environments/environment';
import { AppConfig } from './app.config';
import { OldPortalService } from './services/old-portal.service';
import { UserService } from './services/user.service';
import { FeatureFlagService } from '@headpower/featureflags';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public productId: number = 577;
    public deputyFeatureProductId!: number;
    public currentUser: CurrentUser = new CurrentUser();
    public locationUri: string;
    public claims: any;
    public searchId: string = '';
    public searchIdError: boolean = false;
    public searchIdWarning: boolean = false;
    public menuItems: MenuItem[] = [];
    public menuIsHidden: boolean = true;
    public environmentVariables: any;
    public portalBaseUri: string;
    public accountBaseUri: string;
    public backendAccountUri: string;
    public backendPortalUri: string;
    public currentLocale: string;
    public searchActive: boolean = false;
    public productionMode: boolean;
    public appVersion: string = environment.version;

    public deputyFeatureProductRight: boolean = false;

    // Is application running on a handset
    public mobile: boolean = false;

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }
    private _isLoggedIn: boolean = false;

    constructor(
        private oAuthService: OAuthService,
        private oAuthStorage: AppOAuthStorage,
        private portalService: OldPortalService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private appInsightsService: ApplicationInsightsService,
        private layoutBreakpointService: LayoutBreakpointService,
        private config: AppConfig,
        private featureFlagService: FeatureFlagService,
        private sharedService: SharedService,
        private blahService: BlahService,
        private readonly iconRegistry: MatIconRegistry,
    ) {
        this.initBaseProperties();

        this.initAuthentication();

        // Initialize Application Insights
        this.appInsightsService.init(this.config.applicationInsightsInstrumentationKey, true);

        // Observe device screen size and change the layout accordingly
        this.layoutBreakpointService.observer$
            .subscribe(result => this.mobile = result.handset);
    }

    ngOnInit() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.route),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                let fullTitle = 'Portal | HeadPower';

                if (event.title) {
                    fullTitle = `${event.title} | ${fullTitle}`;
                }

                this.titleService.setTitle(fullTitle);
                this.locationUri = document.location.href;
            });

        // Use material symbols outlined as default for mat-icon, instead of deprecated material icons
        this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    }

    private initBaseProperties() {
        this.environmentVariables = {
            environment: environment,
            config: this.config.getAll()
        };

        this.deputyFeatureProductId = this.config.deputyFeatureProductId;

        this.accountBaseUri = this.config.accountBaseUri;
        this.portalBaseUri = this.config.portalBaseUri;
        this.backendAccountUri = this.config.backendAccountUri;
        this.backendPortalUri = this.config.backendPortalUri;

        this.sharedService.portalBaseUri = this.portalBaseUri;
        this.sharedService.backendAccountUri = this.backendAccountUri;
        this.sharedService.backendPortalUri = this.backendPortalUri;

        this.productionMode = environment.production;
    }

    public logOut() {
        this.oAuthStorage.clear();

        this.oAuthService.logOut();
    }

    private createAuthConfig() {
        const baseUri = document.baseURI.replace(/\/$/, ''); // Remove possible final slash
        const authConfig = new AuthConfig();

        // Identity provider's uri
        authConfig.issuer = this.config.accountBaseUri + 'identity';

        // Set flow to authorization code
        authConfig.responseType = 'code';

        // Client secret, required by auth endpoint
        authConfig.dummyClientSecret = this.config.clientSecret;

        // Auth redirect (callback) uri
        authConfig.redirectUri = baseUri + '/auth/callback';

        // Client id
        authConfig.clientId = 'headpower_web_app';

        // Scopes used by the app
        authConfig.scope = 'openid profile email offline_access headpower:api headpower:app roles';

        // Activate session checks to get event if session has terminated,
        // f.ex. user has logged out from another app.
        authConfig.sessionChecksEnabled = this.config.environmentName !== 'LOCAL';
        authConfig.sessionCheckIntervall = 10000; // 10 seconds

        // Set tolerance for the token expiration time according to auth server
        authConfig.clockSkewInSec = 5 * 60; // 5 minutes

        // FYI: Debug should be enabled only when debugging login flow
        authConfig.showDebugInformation = false;

        return authConfig;
    }

    private initAuthentication() {
        this.oAuthService.configure(this.createAuthConfig());
        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

        // Uses refresh token so offline_access claim is mandatory
        this.oAuthService.setupAutomaticSilentRefresh();

        // Load discovery document. This will also start the login process.
        this.oAuthService.loadDiscoveryDocument();

        this.oAuthService.events
            .subscribe(event => {
                switch (event.type) {
                    case 'token_received':
                        // Token is received when user freshly authenticates and when token is silently refreshed.
                        // Load/refresh user profile after token is received.
                        this.oAuthService.loadUserProfile();
                        break;

                    case 'user_profile_loaded':
                        this.handlePostTokenReceive();

                        // Call login handlers only in login flow
                        if (!this.isLoggedIn) {
                            this.oAuthStorage.setAppItem('logged_in', 'true');

                            this.handleLoginSideEffects();
                            this.handlePostLogin(true);
                        }
                        break;

                    case 'session_changed':
                    case 'session_terminated':
                        console.log(`Authentication ${event.type.replace('_', ' ')}, logging out...`);
                        this.logOut();
                        break;
                }

                // Catch any error event and log them to AI
                if (event instanceof OAuthErrorEvent) {
                    console.error('[Auth]', event);

                    this.appInsightsService.trackException(
                        new Error(`${event.type} (${(event.reason as any)?.error?.error || '-'})`));
                }
            });

        // Check if user has authenticated before
        if (this.oAuthService.hasValidIdToken()) {
            this.handlePostTokenReceive();

            const wasLoggedIn = !!this.oAuthStorage.getAppItem('logged_in');

            // Check if user has authenticated in another app
            if (!wasLoggedIn) {
                this.oAuthStorage.setAppItem('logged_in', 'true');

                this.handleLoginSideEffects();
            }

            this.handlePostLogin(false);
        }
    }

    // FYI: This method is called asynchronously or synchronously after user has authenticated (freshly or before)
    //      and profile is loaded (login flow is in progress).
    //      Called also every time when token is silently refreshed.
    private handlePostTokenReceive() {
        // Set claims
        this.setClaimProperties();
    }

    // FYI: This method is called asynchronously or synchronously after user has authenticated (freshly or before).
    //      Meant for ex. data collection, previous session data removal etc.
    //      Called only once per app session.
    private handleLoginSideEffects() {
        // Send product usage statistics
        const redirectUrl = this.oAuthStorage.getAppItem('post_login_redirect_url') || '/';

        this.portalService.addUsageStatistics(redirectUrl)
            .subscribe();
    }

    // FYI: This method is called once in two possible ways:
    //      1. Asynchronously (when user has freshly authenticated).
    //      2. Synchronously from constructor (when user has authenticated before in current session).
    //
    //      To comply with both cases ensure that:
    //        - synchronous initializers (constructor, ngOnInit etc.) wont access properties which are not defined until in this method.
    //        - every property this method will access is defined before initAuthenticaton call, e.g. menuItems.
    private handlePostLogin(freshLogin: boolean) {
        this._isLoggedIn = true;

        // Init services
        this.initFeatureFlagsService();

        // Handle usage rights and add menu items
        this.handleUsageRights();

        // Add page loaded class for robot tests
        setTimeout(() => document.body.classList.add('page-loaded'), 250);
    }

    private initFeatureFlagsService() {
        const flagClaims = {
            'env': this.config.environmentName,
            ...this.claims
        };

        // Configure FeatureFlags
        this.featureFlagService.config = {
            environment: this.environmentVariables,
            claims: flagClaims,
            baseUri: 'https://backend.headpower.fi',
            filterClaims: [
                'iss', 'aud', 'exp', 'nbf', 'nonce', 'iat',
                'at_hash', 'sid', 'auth_time', 'idp', 'amr'
            ]
        };
        this.featureFlagService.init();
    }

    private handleUsageRights() {
        forkJoin([
            this.sharedService.hasProductUsageRight(this.deputyFeatureProductId, false)
        ])
            .subscribe(results => {
                this.deputyFeatureProductRight = results[0];

                this.setAppMenuItems();
            });
    }

    private setAppMenuItems() {
        const items = [];

        if (this.userService.userHasRoleClaim('CustomerAdmin')) {
            items.push(new MenuItem('portal.portalAdmin', `${this.portalBaseUri}NewPortalAdmin/`, { translate: true, icon: 'supervisor_account', newWindow: true }));
            items.push(new MenuItem('portal.invitePerson', '/InvitePerson', { translate: true, icon: 'mail_outline' }));
        }

        if (this.deputyFeatureProductRight) {
            // FYI: We have to use the full url redirect here
            //      because layout doesn't currently support route fragments in MenuItems
            const profileUrl = this.portalBaseUri + 'newportal/Profile#PersonStatuses';

            items.push(new MenuItem('portal.personStatus.deputiesAndStatuses', profileUrl, { translate: true, icon: 'person_off' }));
        }

        this.menuItems = items;
    }

    private setClaimProperties() {
        const claims = this.oAuthService.getIdentityClaims();
        this.claims = claims;

        this.currentUser.userId = claims['sub'];
        this.currentUser.id = claims['id'];
        this.currentUser.givenName = claims['given_name'];
        this.currentUser.familyName = claims['family_name'];
        this.currentUser.phone = claims['phone_number'];
        this.currentUser.email = claims['email'];
        this.currentUser.picture = claims['picture'];
        this.currentUser.accessToken = this.oAuthService.getAccessToken();
        this.currentUser.companyName = claims['headpower:company_name'];
        this.currentUser.companyOldId = claims['headpower:company_id'];
        // make sure that roles is an array (can be string or undefined if claims contain only one role or no roles at all)
        this.currentUser.limitedUser = [].concat(claims['role']).some((role) => role === 'LimitedUser');

        // Set locale only in login flow
        if (!this.isLoggedIn) {
            this.setBlahLanguage();
        }
    }

    /**
    * Set searchActive property to true
    */
    public toggleSearch() {
        this.searchActive = !this.searchActive;
    }

    /**
    * Sets the blah language based on locale claim.
    */
    private setBlahLanguage() {
        const claimLocale = this.claims['locale'] as string;

        if (claimLocale) {
            const separatorIndex = claimLocale.indexOf('-');
            const localeLanguage = separatorIndex > -1 ? claimLocale.substring(0, separatorIndex) : claimLocale;
            const locale = this.blahService.availableLocales.find(l => l.startsWith(localeLanguage)) ?? 'fi-FI';

            this.blahService.changeLocale(locale);
        }
    }
}
