<div *ngIf="showSignature">
    <kendo-signature #Signature id="Signature" class="signature" [(value)]="signatureData"
        [strokeWidth]="2.5" [popupScale]="2" [smooth]="true" [maximizable]="false" [hideLine]="false" [readonly]="false"
        size="medium"></kendo-signature>
    <div class="buttons">
        <button mat-raised-button id="add-signature-button" color="accent" (click)="saveSignature(true)"
            [disabled]="signatureData === ''">
            <mat-icon>save</mat-icon>
            {{ 'default.save' | blah | uppercase}}
        </button>
        <button mat-button id="cancel-signature-button" (click)="saveSignature(false)">
            {{ 'default.cancel' | blah | uppercase}}
        </button>
    </div>
</div>
