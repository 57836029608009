import { Component, OnInit, OnDestroy, Inject, ErrorHandler } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { LayoutBreakpointService, ToasterService, SharedService } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-helpdesk',
    templateUrl: './helpdesk.component.html',
    styleUrls: ['./helpdesk.component.scss']
})
export class HelpdeskComponent implements OnInit, OnDestroy {

    handsetLayout: boolean;
    currentLocale: string;
    claims: any;
    helpdeskForm: UntypedFormGroup;
    supportRequest: boolean;
    location: string;
    productId: number;
    submitting: boolean = false;
    submitted: boolean = false;
    contactInformationText: string;

    private destroy$: Subject<void> = new Subject();

    constructor(private layoutBreakpoint: LayoutBreakpointService,
        private route: ActivatedRoute,
        private oAuthService: OAuthService,
        private toaster: ToasterService,
        private sharedService: SharedService,
        private blahService: BlahService,
        @Inject(ErrorHandler) public errorHandler: any,
        private fb: UntypedFormBuilder) { }

    ngOnInit() {
        this.layoutBreakpoint.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.handsetLayout = result.handset);

        this.blahService.localeChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(newLocale => {
                this.currentLocale = newLocale
                this.getContactInformationTexts();
            });

        this.currentLocale = this.blahService.currentLocale;

        this.getContactInformationTexts();

        this.claims = this.oAuthService.getIdentityClaims();

        this.helpdeskForm = this.fb.group({
            name: [this.fullName, [Validators.required]],
            email: [this.claims['email'] || '', [Validators.required, Validators.email]],
            phone: [this.claims['phone_number'] || ''],
            company: [this.claims['headpower:company_name'] || ''],
            subject: ['', [Validators.required]],
            description: ['', [Validators.required]],
            sendCopy: [false]
        });

        this.route.data.subscribe(data => {
            this.supportRequest = data.type == 'support';
        });
        this.route.queryParamMap.subscribe(params => {
            this.location = decodeURIComponent(params.get('location'));
            this.productId = +params.get('product');
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get fullName(): string {
        return `${(this.claims['given_name'] || '')} ${(this.claims['family_name'] || '')}`;
    }

    errors(control: string): ValidationErrors {
        return this.helpdeskForm.get(control).errors;
    }

    /**
    * Gets contact information text/parts
    */
    private getContactInformationTexts() {

        return this.sharedService.getTextObject(5, 1, this.blahService.currentLocale)
            .subscribe(result => {
                this.contactInformationText = result.TextParts[0].Value;
            },
                error => {
                    console.error('Failed to get Helpdesk contact information Text/Parts');
                });
    }

    submitRequest() {
        // Halt execution if already submitting
        if (this.submitting) {
            return;
        }

        // Submit request if form is valid otherwise show error toaster
        if (this.helpdeskForm.invalid) {
            this.toaster.create(this.blahService.blah('default.formContainsErrors'));
            return;
        };

        this.submitting = true;

        const values = this.helpdeskForm.value;

        this.sharedService.sendHelpdeskRequest({
            supportRequest: this.supportRequest,
            location: this.location,
            productId: this.productId,
            name: values.name,
            email: values.email,
            phone: values.phone,
            company: values.company,
            subject: values.subject,
            description: values.description,
            sendCopy: values.sendCopy
        }).subscribe(
            result => {
                this.submitting = false;
                this.submitted = true;
            }, error => {
                this.submitting = false;
                this.errorHandler.handleError(error);
            });
    }
}