import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, EMPTY } from 'rxjs';
import { AppConfig } from '../app.config';
import { OAuthService } from 'angular-oauth2-oidc';
import { UsageStatistics } from './usage-statistics';
import { Product } from '../products/products-list/product';


// TODO: deprecated. Can be removed after new account has been deployed to production.
export class CreateUserInvitationEmailDto {
    UserInvitationReceiverEmail: string;
}

export class MarkNewsAsReadParameters {

    // Product id
    ProductId: number;
}

@Injectable({
    providedIn: 'root'
})
export class OldPortalService {
    private setFavoritesUrl: string = this.config.backendPortalUri + 'WebAPI/api/Product/ManageFavoriteApp';
    private newsApi = {
        unexpiredUrl: this.config.backendPortalUri + 'EventStream.WebAPI/odata/GetUnexpiredNewsForProduct?productId={productId}&language={language}',
        unexpiredAndUnCheckedUrl: this.config.backendPortalUri + 'EventStream.WebAPI/odata/GetUnexpiredAndUncheckedNewsForProduct?productId={productId}&language={language}',
        unexpiredNewsForAllLicencedProductsUri: this.config.backendPortalUri + 'EventStream.WebAPI/odata/GetUnexpiredNewsForAllLicencedProducts?language={language}',
        unexpiredAndUncheckedNewsForAllLicencedProductsUri: this.config.backendPortalUri + 'EventStream.WebAPI/odata/GetUnexpiredAndUncheckedNewsForAllLicencedProducts?language={language}',
        specificNewsUrl: this.config.backendPortalUri + 'EventStream.WebAPI/odata/GetSpecificNews?newsId={newsId}',
        markAsReadUrl: this.config.backendPortalUri + 'EventStream.WebAPI/odata/MarkEventLogAsRead',
        markNewsAsReadUrl: this.config.backendPortalUri + 'EventStream.WebAPI/odata/MarkNewsAsRead'
    };

    // TODO: deprecated. Can be removed after new account has been deployed to production.
    private createUserInvitationUrl: string = this.config.backendAccountUri + 'Account.WebAPI/api/UserIdentities/CreateUserInvitation';

    // TODO: deprecated. Can be removed after new account has been deployed to production.
    private checkUserIdentityUrl: string = this.config.backendAccountUri + 'Account.WebAPI/api/UserIdentities/CheckUserIdentity?email={email}';

    private usageStatisticsUrl: string = this.config.backendPortalUri + 'WebAPI/api/UsageStatistics/Post?apiKey={0}';
    private userAdministratorUrl: string = this.config.backendPortalUri + 'WebAPI/odata/UserAdministrator({userId})';
    private userIdentitiesOdataUrl: string = this.config.backendAccountUri + 'Account.WebAPI/odata/UserIdentitiesoData({identityId})';
    private hasUsageDataBeenSent: boolean = false;
    private productData: Subject<Array<Product>> = new Subject<Array<Product>>();

    constructor(private oauthService: OAuthService, private http: HttpClient, private config: AppConfig) { }

    public getUnexpiredNews(productId: string, language: string): Observable<any> {

        const url = this.newsApi.unexpiredUrl
            .replace('{productId}', encodeURIComponent(productId))
            .replace('{language}', encodeURIComponent(language));

        return this.http.get(url);
    }

    public getUncheckedNews(productId: string, language: string): Observable<any> {

        const url = this.newsApi.unexpiredAndUnCheckedUrl
            .replace('{productId}', encodeURIComponent(productId))
            .replace('{language}', encodeURIComponent(language));

        return this.http.get(url);
    }

    public getAllUncheckedNews(language: string): Observable<any> {

        const url = this.newsApi.unexpiredAndUncheckedNewsForAllLicencedProductsUri
            .replace('{language}', encodeURIComponent(language));

        return this.http.get(url);
    }

    public getAllNews(language: string): Observable<any> {

        const url = this.newsApi.unexpiredNewsForAllLicencedProductsUri
            .replace('{language}', encodeURIComponent(language));

        return this.http.get(url);
    }

    public getSpecificNews(newsId: string): Observable<any> {
        const url = this.newsApi.specificNewsUrl
            .replace('{newsId}', newsId);

        return this.http.get(url);
    }

    public markNotificationAsRead(objectId: string): Observable<any> {

        return this.http.post(this.newsApi.markAsReadUrl, {
            EventId: objectId
        });
    }

    /**
    * Marks news as read
    * @param markNewsAsReadParameters Product id
    */
    public markNewsAsRead(markNewsAsReadParameters: MarkNewsAsReadParameters): Observable<any> {

        const url = this.newsApi.markNewsAsReadUrl;

        return this.http.post(url, markNewsAsReadParameters);
    }

    /**
     *Creates user invitation
     * @param createUserInvitationEmaiDto CreateUserInvitationEmaiDto
     */
    // TODO: deprecated. Can be removed after new account has been deployed to production.
    public createUserInvitation(createUserInvitationEmaiDto: CreateUserInvitationEmailDto): Observable<any> {
        const url = this.createUserInvitationUrl;

        return this.http.post(url, createUserInvitationEmaiDto);
    }

    // TODO: deprecated. Can be removed after new account has been deployed to production.
    public checkIdentityExistence(email: string): Observable<any> {
        const url = this.checkUserIdentityUrl
            .replace('{email}', encodeURIComponent(email));

        return this.http.get(url);
    }

    public setFavoriteStatus(applicationId: number, status: boolean): Observable<any> {
        return this.http.post(this.setFavoritesUrl, {
            ApplicationId: applicationId,
            Create: status
        });
    }

    public addUsageStatistics(page: string): Observable<any> {
        if (!this.hasUsageDataBeenSent) {
            const url = this.usageStatisticsUrl.replace('{0}', this.config.productApiKey);
            const claims: any = this.oauthService.getIdentityClaims();

            let usageStatistics = new UsageStatistics();
            usageStatistics.UserId = claims['sub'];
            usageStatistics.CompanyId = claims['headpower:company_uuid'];
            usageStatistics.Page = page;
            usageStatistics.RemoteAddress = '';

            this.hasUsageDataBeenSent = true;

            return this.http.post(url, usageStatistics);
        } else {
            return EMPTY;
        }
    }

    public getUserInfo(userId: string): Observable<any> {
        const requestUrl = this.userAdministratorUrl
            .replace('{userId}', userId);

        return this.http.get(requestUrl);
    }

    public updateUserInfo(userId: string, updateValues: any): Observable<any> {
        const requestUrl = this.userAdministratorUrl
            .replace('{userId}', userId);

        return this.http.patch(requestUrl, updateValues);
    }

    public getUserIdentity(identityId: string): Observable<any> {
        const requestUrl = this.userIdentitiesOdataUrl
            .replace('{identityId}', identityId);

        return this.http.get(requestUrl);
    }

    public setProductData(products: Array<Product>) {
        this.productData.next(products);
    }

    public getProductData(): Observable<Array<any>> {
        return this.productData.asObservable();
    }
}
