import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BlahModule } from '@headpower/blah-ng';
import { PlaceholderModule, SelectModule, HpoIconModule } from '@headpower/components';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';

import { SharedModule } from '../shared/shared.module';
import { ProductsListComponent } from './products-list/products-list.component';

@NgModule({
    declarations: [
        ProductsListComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        BlahModule,
        PlaceholderModule,
        SelectModule,
        SharedModule,
        HpoIconModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatSelectModule,
        MatCardModule,
        MatGridListModule
    ],
    exports: [
        ProductsListComponent
    ]
})
export class ProductsModule { }
