<h2 mat-dialog-title>{{ title }}</h2>

<div mat-dialog-content>
    <span class="importantNewsContent"
        [innerHtml]="content"></span>
</div>

<div mat-dialog-actions
    class="pull-right">
    <button mat-button
        [id]="'confirm-important-news'"
        *ngIf="!fullScreenDialog"
        (click)="confirm()">
        {{ 'default.close' | blah }}
    </button>
</div>