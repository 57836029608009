<ng-template #infoItemTemplate>

    <app-info-item></app-info-item>

</ng-template>

<div class="content-padding desktop">

    <!-- Desktop layout -->
    <div class="home-grid-container"
        *ngIf="!handsetLayout">

        <div class="product-card">
            <div class="card-panel-title">
                <mat-icon>apps</mat-icon>{{ 'portal.products' | blah }}
            </div>
            <div>
                <mat-card appearance="outlined" class="list-card">
                    <mat-card-content>
                        <app-products-list [showProducts]="true"
                            [mobile]="handsetLayout"></app-products-list>
                    </mat-card-content>
                </mat-card>
            </div>
            <p></p>
            <div class="card-panel-title">
                <mat-icon>link</mat-icon>{{ 'portal.otherServices' | blah }}
            </div>
            <div>
                <mat-card appearance="outlined" class="list-card">
                    <mat-card-content>
                        <app-products-list [showProducts]="false"
                            [mobile]="handsetLayout"></app-products-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="event-card">
            <div class="card-panel-title">{{ 'portal.eventStream' | blah }}</div>
            <mat-card appearance="outlined" class="list-card">
                <mat-card-content>
                    <app-notifications-list></app-notifications-list>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="desktop-infoItems-column">
            <div class="card-panel-title">{{ 'portal.info' | blah }}</div>
            <ng-container *ngTemplateOutlet="infoItemTemplate"></ng-container>
        </div>

    </div>
    <!-- /Desktop layout -->

    <!-- Handset layout -->
    <ng-container *ngIf="handsetLayout">

        <ng-container *ngIf="handsetView == 0">

            <app-products-list [showProducts]="true"
                [mobile]="handsetLayout"></app-products-list>
            <div class="card-panel-title-mobile">
                <mat-icon>link</mat-icon>{{ 'portal.otherServices' | blah }}
            </div>
            <app-products-list [showProducts]="false"
                [mobile]="handsetLayout"></app-products-list>

        </ng-container>

        <ng-container *ngIf="handsetView == 1">

            <app-notifications-list></app-notifications-list>

        </ng-container>

        <ng-container *ngIf="handsetView == 2">

            <ng-container *ngTemplateOutlet="infoItemTemplate"></ng-container>

        </ng-container>

    </ng-container>
    <!-- /Handset layout -->

</div>

<hpo-bottom-nav *ngIf="handsetLayout">

    <hpo-nav-item icon="apps"
        [active]="handsetView == 0"
        (click)="openHandsetView(0)">
        {{ 'portal.products' | blah }}
    </hpo-nav-item>

    <hpo-nav-item icon="restore"
        [active]="handsetView == 1"
        (click)="openHandsetView(1)">
        {{ 'portal.eventStream' | blah }}
    </hpo-nav-item>

    <hpo-nav-item icon="info_outline"
        [active]="handsetView == 2"
        (click)="openHandsetView(2)">
        {{ 'portal.info' | blah }}
    </hpo-nav-item>

</hpo-bottom-nav>
