import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlahService } from '@headpower/blah-ng';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {

    public title?: string;
    public message?: string;
    public confirmText!: string;
    public cancelText!: string;

    private translate: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: any,
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private blahService: BlahService) { }

    ngOnInit() {
        this.dialogRef.updateSize('480px');

        if (typeof this.dialogData.translate === 'boolean') {
            this.translate = this.dialogData.translate;
        }

        if (this.dialogData.title) {
            this.title = this.translate ?
                this.blahService.blah(this.dialogData.title) :
                this.dialogData.title;
        }

        if (this.dialogData.message) {
            this.message = this.translate ?
                this.blahService.blah(this.dialogData.message) :
                this.dialogData.message;
        }

        this.confirmText = this.dialogData.confirmText ?
            this.translate ?
                this.blahService.blah(this.dialogData.confirmText) :
                this.dialogData.confirmText :
            'OK';

        this.cancelText = this.dialogData.cancelText ?
            this.translate ?
                this.blahService.blah(this.dialogData.cancelText) :
                this.dialogData.cancelText :
            this.blahService.blah('default.cancel');
    }
}
