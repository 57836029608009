import { SafeHtml } from '@angular/platform-browser';

export class Notification {
    public objectId: string;
    public title: string;
    public content: string | SafeHtml;
    public created: string;
    public startDate: string;
    public eventId: number;
    public productId: number;
    public isUnchecked: boolean;
    public isSelected: boolean;
    public important: boolean;
    public productIcon: string;
    public productName: string;

    constructor() {
        this.objectId = '';
        this.title = '';
        this.content = '';
        this.created = '';
        this.startDate = '';
        this.eventId = 0;
        this.productId = 0;
        this.isUnchecked = false;
        this.isSelected = false;
        this.important = false;
        this.productIcon = '';
        this.productName = '';
    }
}
