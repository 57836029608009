import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService, ToastDuration, ApplicationInsightsService, ToastOptions } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';

@Injectable()
export class HpoErrorHandler extends ErrorHandler {

    private appInsightsService?: ApplicationInsightsService;

    constructor(private toaster: ToasterService,
        private blahService: BlahService,
        private injector: Injector
    ) {
        super();
    }

    override handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            // Server error happened

            if (!navigator.onLine) {
                // No Internet connection
                this.toaster.create(this.blahService.blah('error.http.offline'));
                return;
            }

            // Log error
            console.error('HpoErrorHandler: connection error', error.status, error.message)

            let errorMessage = '';
            let toastDuration = ToastDuration.normal;

            switch (error.status) {
                case 0:   // Some browsers will timeout with status 0 (might be a bug)
                case 408: // Request timeout
                case 504: // Gateway timeout
                    errorMessage = this.blahService.blah('error.http.timeout');
                    toastDuration = ToastDuration.persistent;
                    break;

                case 400: // Bad Request
                    errorMessage = error.error?.Message ?
                        this.blahService.blah(error.error.Message) :
                        this.blahService.blah('default.unknownError');
                    break;

                case 403: // Forbidden
                    errorMessage = this.blahService.blah('default.accessDenied');
                    break;

                case 404: // Not Found
                    errorMessage = this.blahService.blah('default.http404');
                    break;

                // If other error display generic message
                default:
                    errorMessage = this.blahService.blah('error.http.generic');
            }

            let options: ToastOptions = {
                duration: toastDuration
            };

            if (toastDuration === ToastDuration.persistent) {
                options.actionLabel = 'OK';
            }

            // Display error for user
            this.toaster.create(errorMessage, options);
        } else {
            // Client Error happened. Log it to application insights

            // getting this reference from constructor causes a cyclic dependency. Get from injector instead
            if (!this.appInsightsService) {
                this.appInsightsService = this.injector.get(ApplicationInsightsService);
            }

            this.appInsightsService.trackException(error);
        }

        super.handleError(error);
    }
}
