import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ProfileRedirectGuard {

    constructor(
        private router: Router) { }

    // FYI: A simple guard to redirect all urls with userProfile query param to Profile page.
    //      Solely for backwards compatibility with old applications navigating to profile.
    //
    //      Can be removed after no application uses userProfile param anymore.

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (route.queryParamMap.has('userProfile')) {
            this.router.navigate(['/Profile']);
            return false;
        }

        return true;
    }
}
