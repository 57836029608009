<div style="display: flex; width: 100px; flex: 1 0"></div>

<hpo-card [mobile]="mobile">
    <hpo-card-header color="primary">
        {{ 'default.userProfile' | blah }}
    </hpo-card-header>

    <hpo-card-content>

        <div class="mat-dialog-custom-container">
            <!-- Placeholder skeleton -->
            <div *ngIf="loading"
                class="placeholder-container">
                <hpo-placeholder type="row"></hpo-placeholder>
                <hpo-placeholder type="input"
                    [rows]="5"></hpo-placeholder>
                <hpo-placeholder type="input"
                    [rows]="5"></hpo-placeholder>
            </div>

            <div *ngIf="!loading && userProfileForm">

                <!-- Actual content -->
                <mat-tab-group *ngIf="!loading"
                    dynamicHeight
                    [(selectedIndex)]="tabIndex">

                    <mat-tab label="{{ 'layout.basicInformation' | blah }}">
                        <form class="form-container"
                            [formGroup]="userProfileForm"
                            #userProfileFg="ngForm"
                            novalidate
                            (change)="submitForm();">

                            <!-- First name -->
                            <div gridRow="1"
                                gridColumn="1 / 3">
                                <mat-form-field>
                                    <mat-label>{{ 'layout.firstName' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfileFirstName"
                                        required
                                        formControlName="firstName">
                                    <mat-error *ngIf="errors('firstName')?.required">
                                        {{ 'layout.validation.required' | blah }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- Last name -->
                            <div gridRow="1"
                                gridColumn="3 / 5">
                                <mat-form-field>
                                    <mat-label>{{ 'layout.lastName' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfileLastName"
                                        required
                                        formControlName="lastName">
                                    <mat-error *ngIf="errors('lastName')?.required">
                                        {{ 'layout.validation.required' | blah }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- Position -->
                            <div gridRow="2"
                                gridColumn="1 / 3">
                                <mat-form-field>
                                    <mat-label>{{ 'layout.position' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfilePosition"
                                        formControlName="position">
                                </mat-form-field>
                            </div>

                            <!-- Phone -->
                            <div gridRow="2"
                                gridColumn="3 / 5">
                                <mat-form-field>
                                    <mat-label>{{ 'layout.phone' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfilePhone"
                                        formControlName="phone">
                                    <mat-error *ngIf="errors('phone')">
                                        {{ 'layout.validation.invalidPhone' | blah }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- Language -->
                            <div gridRow="3"
                                gridColumn="1 / 3">
                                <mat-form-field>
                                    <mat-label>{{ 'layout.language' | blah }}</mat-label>
                                    <mat-select id="UserProfileLanguage"
                                        formControlName="language"
                                        (selectionChange)="submitForm();">
                                        <!-- TODO: Some day these should come from the api -->
                                        <mat-option value="">{{ 'layout.useCompanyChoice' | blah }}</mat-option>
                                        <mat-option value="fi">{{ 'layout.locales.fi-FI' | blah }}</mat-option>
                                        <mat-option value="sv">{{ 'layout.locales.sv-SE' | blah }}</mat-option>
                                        <mat-option value="en">{{ 'layout.locales.en-US' | blah }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Time zone -->
                            <div gridRow="3"
                                gridColumn="3 / 5">
                                <mat-form-field>
                                    <mat-label>{{ 'layout.timeZone' | blah }}</mat-label>
                                    <mat-select id="UserProfileTimezone"
                                        formControlName="timeZone"
                                        (selectionChange)="submitForm();">
                                        <mat-option *ngFor="let timeZone of timeZones"
                                            [value]="timeZone.ValueId">
                                            {{ timeZone.Name == 'USECOMPANYTIMEZONE' ?
                                            ('layout.useCompanyChoice' |
                                            blah) : timeZone.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </form>

                        <div *ngIf="!showSignature && !loadingSignature"
                            style="padding-top: 20px; padding-bottom: 10px;">
                            <button mat-stroked-button
                                id="add-signature-button"
                                color="accent"
                                (click)="saveDisabled=true; openSignatureDialog();">
                                <mat-icon>add</mat-icon>
                                {{'default.addSignature' | blah | uppercase}}
                            </button>
                        </div>

                        <div *ngIf="showSignature && !loadingSignature">
                            <div class="signature-title">{{'default.signature' | blah }}</div>

                            <div class="signature-line">
                                <div class="signature">
                                    <img class="signature box"
                                        [src]="signatureData">
                                </div>
                                <button class="delete-icon"
                                    mat-icon-button
                                    style="margin-left: 10px;"
                                    id="signature-delete-button"
                                    (click)="deleteSignature();"
                                    matTooltip="{{ 'default.delete' | blah }}">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="{{ 'layout.userName' | blah }}">

                        <div class="form-container">

                            <!-- Alternative username -->
                            <div gridRow="1"
                                gridColumn="1 / 3">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'layout.userName' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfileAltUsername"
                                        placeholder="{{ 'layout.usernameNotDefined' | blah }}"
                                        [value]="altUsername"
                                        (change)="changeAltUsername($event.target.value)">
                                    <mat-hint>
                                        {{ 'layout.hints.atLeastChars' | blah:[6] }} {{
                                        'layout.hints.mustBeTrimmed' | blah }}
                                    </mat-hint>

                                </mat-form-field>
                            </div>

                            <div gridRow="2"
                                gridColumn="1 / 5">
                                <p>{{ 'layout.fieldsBelowNotEditable' | blah }}.</p>
                            </div>

                            <!-- Email -->
                            <div gridRow="3"
                                gridColumn="1 / 3">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'layout.email' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfileEmail"
                                        readonly
                                        [value]="userIdentity.Email">
                                </mat-form-field>
                            </div>

                            <!-- Last login -->
                            <div gridRow="3"
                                gridColumn="3 / 5">
                                <mat-form-field floatLabel="always">
                                    <mat-label>{{ 'layout.lastLogin' | blah }}</mat-label>
                                    <input matInput
                                        id="UserProfileLastLogin"
                                        readonly
                                        value="{{ userIdentity.LastLogin | dayjs: dateTimeFormat : currentLocale }}">
                                </mat-form-field>
                            </div>

                            <!-- Change password -->
                            <div gridRow="4"
                                gridColumn="1 / 3">
                                <a *ngIf="!ff_newAccount"
                                    href="{{accountBaseUri}}Account/ChangePassword/?redirectUrl={{location}}&portalLanguage={{currentLocale}}">{{
                                    'layout.changePassword' | blah }}</a>
                                <a *ngIf="ff_newAccount"
                                    href="{{accountBaseUri}}identity/Account/ChangePassword">{{
                                    'layout.changePassword' | blah }}</a>
                            </div>

                        </div>
                        <div class="date-field-grid">
                            <!-- Password changed -->
                            <div gridRow="1"
                                gridColumn="1">
                                {{ 'layout.passwordChanged' | blah }}:
                            </div>
                            <div gridRow="1"
                                gridColumn="2">
                                {{ userIdentity.LastPWChange | dayjs: dateTimeFormat : currentLocale}}
                            </div>
                            <!-- Account activated-->
                            <div gridRow="4"
                                gridColumn="1">
                                {{ 'layout.accountActivated' | blah }}:
                            </div>
                            <div gridRow="4"
                                gridColumn="2">
                                {{ userIdentity.LastActivation | dayjs: dateTimeFormat : currentLocale }}
                            </div>
                            <!-- EULA accepted -->
                            <div gridRow="2"
                                gridColumn="1">
                                {{ 'layout.eulaAccepted' | blah }}:
                            </div>
                            <div gridRow="2"
                                gridColumn="2">
                                {{ userIdentity.EulaAccepted === '1900-01-01T00:00:00' ? ('layout.notAccepted' |
                                blah) :
                                (userIdentity.EulaAccepted | dayjs: dateTimeFormat : currentLocale) }}
                            </div>
                            <!-- Person data permit accepted -->
                            <div gridRow="3"
                                gridColumn="1">
                                {{ 'layout.personDataPermit' | blah }}:
                            </div>
                            <div gridRow="3"
                                gridColumn="2">
                                {{ userIdentity.PersonDataPermit === '1900-01-01T00:00:00' ?
                                ('layout.notAccepted' |
                                blah) : (userIdentity.PersonDataPermit | dayjs: dateTimeFormat : currentLocale)
                                }}
                            </div>
                            <div style="grid-row-start: 1; grid-row-end: 4;"
                                gridColumn="3">
                                <a href="{{ endUserAgreement }}"
                                    target="_blank">{{ 'layout.userAgreement' |
                                    blah }}</a>
                                <div [innerHTML]="eulaText"></div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="{{ 'layout.settings' | blah }}">

                        <!-- Communication preferences -->
                        <div class="tab-container">
                            <h4 class="mat-h2">{{ 'layout.communicationPreferences' | blah }}</h4>

                            <div class="form-container"
                                style="margin-top: 0">

                                <mat-checkbox gridRow="1"
                                    gridColumn="1 / 5"
                                    id="UserProfileContactInfoPermit"
                                    class="checkbox-container"
                                    [checked]="contactInfoPermit"
                                    (change)="contactInfoPermitChange($event.checked)">
                                    {{ 'layout.allowContactInfoAndSearch' | blah }}
                                </mat-checkbox>

                                <mat-checkbox gridRow="2"
                                    gridColumn="1 / 5"
                                    id="UserProfileMassMail"
                                    class="checkbox-container"
                                    [checked]="massMail"
                                    (change)="markMassMail($event.checked)">
                                    {{ 'layout.productNewsContent' | blah }}
                                </mat-checkbox>
                            </div>
                        </div>

                        <!-- Open product to new tab -->
                        <div class="tab-container">
                            <h4 class="mat-h2">{{ 'layout.otherSettings' | blah }}</h4>
                            <mat-checkbox id="OpenProductToNewTab"
                                [checked]="openProductToNewTab"
                                (change)="markOpenProductToNewTab($event.checked)">
                                {{ 'layout.openProductsToNewTab' | blah }}
                            </mat-checkbox>
                        </div>


                        <!-- DefaultBusinessDomain -->
                        <div class="tab-container">
                            <mat-form-field class="narrow">
                                <mat-label>{{ 'default.businessDomain' | blah }}</mat-label>
                                <mat-select id="DefaultBusinessDomain"
                                    [(value)]="defaultBusinessDomain"
                                    (selectionChange)="markBusinessDomain($event);">
                                    <mat-select-trigger>
                                        <span *ngIf="defaultBusinessDomain != 'None'"
                                            style="padding-right: .5em;">
                                            <app-businessdomain-icon [businessdomain]="defaultBusinessDomain"
                                                [size]="15"></app-businessdomain-icon>
                                        </span>
                                        <span *ngIf="!!defaultBusinessDomain">{{ 'portal.businessDomain.' + defaultBusinessDomain | blah }}</span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let businessDomain of businessDomains"
                                        [value]="businessDomain.value">
                                        <div style="display: flex; align-items: center;"
                                            [id]="id + '-select-option-' + businessDomain.value">
                                            <div style="padding-left: 1em; order: 2;"> {{businessDomain.translatedValue}} </div>
                                            <div style="order: 1;">
                                                <app-businessdomain-icon [businessdomain]="businessDomain.value"></app-businessdomain-icon>
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </mat-tab>

                    <mat-tab *ngIf="ff_newAccount"
                        label="{{ 'portal.userInvitations.tabHeader' | blah }}">
                        <app-profile-user-invitations [mobile]="mobile">
                        </app-profile-user-invitations>
                    </mat-tab>

                    <mat-tab *ngIf="hasDeputyFeatureRight"
                        label="{{ 'portal.personStatus.deputiesAndStatuses' | blah }}">
                        <app-profile-person-statuses [mobile]="mobile">
                        </app-profile-person-statuses>
                    </mat-tab>

                    <mat-tab label="{{ 'layout.gravatarGuide' | blah }}">

                        <div style="padding-top: 15px;">
                            <!-- Finnish -->
                            <ng-container *ngIf="currentLocale == 'fi-FI'">
                                <p>Gravatar on yleinen käytössä oleva profiilikuvien hallintapalvelu.</p>
                                <p>Palvelun avulla voit luoda oman henkilökohtaisen profiilikuvasi, joka toimii
                                    useassa eri
                                    palvelussa, mukaanlukien HeadPower
                                    Portalissa.</p>
                                <p>Alla on ohjeet, miten voit luoda oman Gravatarisi:</p>
                                <ol class="gravatar-guide">
                                    <li>Siirry osoitteeseen: <a href="https://fi.gravatar.com/connect/"
                                            target="_blank">https://fi.gravatar.com/connect/</a> ja luo
                                        itsellesi
                                        Gravatar-tunnukset. Muista vahvistaa
                                        tunnuksesi.</li>
                                    <li>Siirry Gravatar-hallintapaneelissa kohtaan "My Gravatars". Varmista,
                                        että tässä
                                        näkyvä sähköpostiosoite on sama, jota
                                        käytät HeadPower Portalissa. Lisää uusi sähköpostiosoite tarvittaessa.
                                    </li>
                                    <li>Lisää uusi profiilikuva ja rajaa se Gravatarin työkaluilla. Valitse
                                        kuvalle myös
                                        luokitus sen sisällön mukaan.
                                        HeadPower Portalissa voidaan näyttää profiilikuvia R-luokitukseen
                                        saakka.</li>
                                    <li>Uusi Gravatarisi on nyt asetettu. Kuvan päivittyminen HeadPower
                                        Portaliin voi kestää
                                        hetken.</li>
                                </ol>
                            </ng-container>

                            <!-- Swedish -->
                            <ng-container *ngIf="currentLocale == 'sv-SE'">
                                <p>Gravatar är en vanlig hanteringstjänst för profilbilder.</p>
                                <P>Med denna tjänst kan du skapa din egen personell profilbild som följer dig
                                    från sida till
                                    sida inklusive HeadPower-portal.
                                </P>
                                <p>Nedan finns anvisningar om hur du kan skapa din egen Gravatar:</p>
                                <ol class="gravatar-guide">
                                    <li>Gå till adress: <a href="https://sv.gravatar.com/connect/"
                                            target="_blank">https://sv.gravatar.com/connect/</a> och skapa ditt
                                        eget
                                        GravatarID. Kom ihåg att bekräfta ditt
                                        ID.</li>
                                    <li>Gå till "My Gravatars" i kontrollpanelen. Försäkra att e-postadressen
                                        som syns här
                                        är densamma som du använder i
                                        HeadPower-portal. Lägg till en ny e-postadress vid behov.</li>
                                    <li>Lägg till en ny profilbild och beskär den med verktyg i Gravatar. Välj
                                        också
                                        klassificeringen för bilden enligt dess
                                        innehåll. På HeadPower-portal kan visas profilbilder upp till R-värdet.
                                    </li>
                                    <li>Din nyt Gravatar har nu skapats. Uppdatering av en bild till
                                        HeadPower-portal kan ta
                                        en stund.</li>
                                </ol>
                            </ng-container>

                            <!-- English -->
                            <ng-container *ngIf="currentLocale == 'en-US'">
                                <p>Gravatar is a globally recognized Avatar.</p>
                                <p>By using Gravatar you can create your own personal avatar, which will work on
                                    many
                                    different services including HeadPower
                                    Portal.</p>
                                <p>Below is a guide how you can create your own Gravatar:</p>
                                <ol class="gravatar-guide">
                                    <li>Navigate to <a href="https://en.gravatar.com/connect/"
                                            target="_blank">https://en.gravatar.com/connect/</a> and create
                                        yourself an
                                        Gravatar account. Remember to confirm
                                        your email.</li>
                                    <li>Navigate to "My Gravatars". Check that the email you see is exactly the
                                        same you use
                                        in HeadPower Portal. Add new
                                        email if needed.</li>
                                    <li>Upload new avatar and crop it using Gravatar's tools. Choose a rating
                                        for your
                                        avatar. In HeadPower Portal avatars
                                        with rating up to R can be used.</li>
                                    <li>Your new Gravatar is now set. It may take a while for changes to take
                                        effect in
                                        HeadPower Portal.</li>
                                </ol>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </hpo-card-content>
</hpo-card>