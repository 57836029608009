import { Component, OnInit, OnDestroy, Inject, ErrorHandler, ViewEncapsulation, Input } from '@angular/core';
import { SharedService } from '@headpower/layout';
import { OldPortalService } from 'src/app/services/old-portal.service';
import { ProductGroup } from './product-group';
import { Product } from './product';
import { AppConfig } from '../../app.config';
import { BlahService } from '@headpower/blah-ng';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductsListComponent implements OnInit, OnDestroy {
    // True listing shows products, false show list of links
    @Input() showProducts: boolean = true;
    @Input() mobile: boolean = false;

    handsetLayout: boolean;
    loading: boolean = false;
    editingFavorites: boolean = false;
    allProducts: Array<Product> = [];
    products: Array<Product> = [];
    groups: Array<ProductGroup> = [
        { id: 'Suosikit', title: 'portal.favorites', products: Array<Product>() },
        { id: 'Eniten käytetyt', title: 'portal.mostUsed', products: Array<Product>() },
        { id: 'Sovellukset', title: 'portal.applications', products: Array<Product>() },
        { id: 'Ohjeistot', title: 'portal.guides', products: Array<Product>() },
        { id: 'Hallinta-liittymät', title: 'portal.managementInterfaces', products: Array<Product>() },
        { id: 'Muut', title: 'portal.other', products: Array<Product>() }
    ];
    selectedGroup: ProductGroup;
    links: Array<Product> = [];

    currentLocale: string;
    openProductToNewTab: boolean;

    private destroy$: Subject<void> = new Subject();

    constructor(private portalService: OldPortalService,
        private config: AppConfig,
        private blahService: BlahService,
        @Inject(ErrorHandler) public errorHandler: any,
        private sharedService: SharedService) { }

    ngOnInit() {
        this.handsetLayout = this.mobile;

        this.blahService.localeChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.populateData());

        this.populateData();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private populateData() {
        this.loading = true;

        this.currentLocale = this.blahService.currentLocale;

        //Clears products (needed in case if language is changed)
        this.allProducts = [];
        this.products = [];
        this.links = [];

        // Get indication where to open product (new tab or current one)
        this.sharedService.getSettingsValue(13, 1701).subscribe(openProductToNewTab => {

            if (openProductToNewTab == "" || openProductToNewTab == undefined) {
                this.openProductToNewTab = false;
            }
            else if (openProductToNewTab == "0") {
                this.openProductToNewTab = false;
            }
            else {
                this.openProductToNewTab = true;
            }
        }, error => {
            this.errorHandler.handleError(error);
        });

        this.sharedService.getApplicationData(this.currentLocale).subscribe(data => {
            this.portalService.setProductData(data);
            this.parseApplications(data);

            // Add products to groups
            for (const group of this.groups) {

                //Clears groups products (needed in case if language is changed)
                group.products = [];
                for (const product of this.products) {
                    if (product.groupName == group.id) {
                        group.products.push(product);
                    }
                }
            }

            // Add favorites to favorite group
            this.groups[0].products = this.products
                .filter(f => f.personFavoriteApplication == 1);

            this.populateMostUsedProducts();

            // If no favorites, select most used products
            this.selectedGroup = this.groups[0].products.length == 0 ?
                this.groups[1] :
                this.groups[0];

            // Separate products and links
            for (let product of this.allProducts) {
                if (product.priority >= 1000) {
                    this.products.push(product);
                } else {
                    this.links.push(product);
                }
            }
            // Sort products and links alphabetically and then sort favorites on top
            this.products.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => b.personFavoriteApplication - a.personFavoriteApplication)
            this.links.sort((a, b) => a.name.localeCompare(b.name));
            this.loading = false;
        }, error => {
            this.errorHandler.handleError(error);
        });
    }

    // Parse the json data to strongly typed class array
    private parseApplications(applications: any) {
        for (const app of applications) {
            const newProduct = new Product();
            newProduct.documentId = app.DocumentId;
            // Groupname field is used as icon
            newProduct.productIcon = "https://static.headpower.fi/icons/" + (app.GroupName !== "" && app.GroupName !== null ? app.GroupName : 'headpower.svg');
            newProduct.accessGroupName = app.AccessGroupName;
            newProduct.lastAccess = app.LastAccess;
            newProduct.name = app.AccessName;
            newProduct.timesUsedInLast30Days = app.TimesUsedInLast30Days;
            newProduct.url = this.buildUrl(app.Url);
            newProduct.priority = app.AccessPriority;
            newProduct.accessUid = app.Access_UID;
            newProduct.personFavoriteApplication = app.PersonFavoriteApplication;
            newProduct.domain = app.Domain;

            this.allProducts.push(newProduct);
        }
    }

    // Populate 5 most used products
    private populateMostUsedProducts() {
        this.groups[1].products = this.products.sort((p1, p2) => {
            if (p1.timesUsedInLast30Days > p2.timesUsedInLast30Days) {
                return 1;
            }
            if (p1.timesUsedInLast30Days < p2.timesUsedInLast30Days) {
                return -1;
            }
            if (p1.lastAccess > p2.lastAccess) {
                return 1;
            }
            if (p1.lastAccess < p2.lastAccess) {
                return -1;
            }

            return 0;
        }).reverse().splice(0, 5);
    }

    private buildUrl(url: string): string {

        if (url.startsWith('/classic/')) {
            url = this.config.classicBaseUri + url;
        }

        // Ensures that url starts with http or https
        if (!url.startsWith('http')) {
            url = this.config.portalBaseUri.substr(0, this.config.portalBaseUri.length - 1) + url;
        }

        return url;
    }

    isFavorite(product: Product): boolean {
        return this.products.some(p => product.personFavoriteApplication == 1);
    }

    toggleAddFavorites(product: Product) {
        this.editingFavorites = true;

        if (!this.isFavorite(product)) {
            this.portalService.setFavoriteStatus(product.documentId, true).subscribe(data => {

                this.editingFavorites = false;

                product.personFavoriteApplication = 1;

                // Update shared favourites
                this.sharedService.updateFavourites();
                this.products.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => b.personFavoriteApplication - a.personFavoriteApplication);
            }, error => {
                this.errorHandler.handleError(error);
            });
        } else {
            this.portalService.setFavoriteStatus(product.documentId, false).subscribe(data => {

                this.editingFavorites = false;

                product.personFavoriteApplication = 0;

                // Update shared favourites
                this.sharedService.updateFavourites();
                this.products.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => b.personFavoriteApplication - a.personFavoriteApplication);
            }, error => {
                this.errorHandler.handleError(error);
            });
        }
    }

    getBranchIcon(domain: number): string {
        // TODO: Move this to somwhere in global settings
        const domainIconMap = {
            '10': 'branch_electricity',
            '20': 'branch_heat',
            '30': 'branch_communications',
            '40': 'branch_water'
        };

        return domainIconMap[domain.toString()] || null;
    }
}
