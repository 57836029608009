import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-signature-dialog',
    templateUrl: './signature-dialog.component.html',
    styleUrls: ['./signature-dialog.component.scss'],
})
export class SignatureDialogComponent implements OnInit {

    public signatureData: any = '';
    public showSignature = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: any,
        private dialogRef: MatDialogRef<SignatureDialogComponent>) { }

    ngOnInit(): void {
        window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
            //Trick to refresh signature component when changing orientation and signature dialog dimensions change, to make drawing work properly
            this.showSignature = false;
            setTimeout(() => { this.showSignature = true; }, 0);
        });
    }

    saveSignature(save: boolean) {
        this.dialogRef.close({ signatureData: this.signatureData, save: save });
    }

}
