import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BlahModule } from '@headpower/blah-ng';
import { CardModule, ButtonModule, SpinnerModule, NotificationModule } from '@headpower/components';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { InvitePersonPageWrapperComponent } from './user-invitation-page-wrapper.component';
import { UserInvitationService } from './user-invitation-page/user-invitation.service';
import { UserInvitationPageComponent } from './user-invitation-page/user-invitation-page.component';
import { OldInvitePersonPageComponent } from './old-invite-person-page/old-invite-person-page.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BlahModule,
        CardModule,
        ButtonModule,
        SpinnerModule,
        NotificationModule,
        MatInputModule,
        MatIconModule
    ],
    declarations: [
        InvitePersonPageWrapperComponent,
        UserInvitationPageComponent,
        OldInvitePersonPageComponent
    ],
    providers: [
        UserInvitationService
    ]
})
export class InvitePersonModule { }
