import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AppConfig } from 'src/app/app.config';
import { PersonStatus, PersonStatusCreate, PersonStatusPatch } from './person-status.model';

@Injectable()
export class PersonStatusService {

    private portalBackendUrl: string;
    private personStatusesApiUrl: string;

    constructor(
        private http: HttpClient,
        private config: AppConfig) {

        this.portalBackendUrl = this.config.backendPortalUri;
        this.personStatusesApiUrl = this.portalBackendUrl + 'WebAPI/odata/PersonStatuses';
    }

    public getStatusesAsAppointer(personId: string): Observable<PersonStatus[]> {
        const url = this.personStatusesApiUrl + `?$filter=AppointerPersonId eq ${personId}`;

        return this._getStatuses(url);
    }

    public getStatusesAsDeputy(personId: string): Observable<PersonStatus[]> {
        const url = this.personStatusesApiUrl + `?$filter=DeputyPersonId eq ${personId}`;

        return this._getStatuses(url);
    }

    private _getStatuses(url: string): Observable<PersonStatus[]> {
        return this.http.get<any>(url)
            .pipe(
                map(result => result.value as PersonStatus[])
            );
    }

    public createStatus(personStatus: PersonStatusCreate): Observable<PersonStatus> {
        return this.http.post<PersonStatus>(this.personStatusesApiUrl, personStatus);
    }

    public patchStatus(id: string, personStatus: PersonStatusPatch): Observable<void> {
        const url = this.personStatusesApiUrl + `(${id})`;

        return this.http.patch<void>(url, personStatus);
    }

    public deleteStatus(id: string): Observable<void> {
        const url = this.personStatusesApiUrl + `(${id})`;

        return this.http.delete<void>(url);
    }
}
