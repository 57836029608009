import { Component, OnInit, Inject, ErrorHandler, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkNewsAsReadParameters, OldPortalService } from '../../services/old-portal.service';
import { ToasterService, SharedService } from '@headpower/layout';
import { Notification } from '../notification';
import { BlahService } from '@headpower/blah-ng';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-notification-list-page',
    templateUrl: './notification-list-page.component.html',
    styleUrls: ['./notification-list-page.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})

export class NotificationListPageComponent implements OnInit, OnDestroy {

    loading: boolean = true;
    uncheckedNews: Array<Notification> = [];
    currentLocale: string;
    productTitle: string;
    productId: string;
    authorized: boolean = true;
    unReadNotificationsCount: number;
    dateTodayTime: string = new Date().getTime().toString();
    checkedArray = [];
    unCheckedArray = [];

    allNotificationItems: Array<Notification> = [];
    pageItems: Array<Notification> = [];
    private unexpiredNews: Array<Notification> = [];
    private itemsPerPage: number = 16;
    currentPage: number = 1;
    noOfPages: number = 1;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        @Inject(ErrorHandler) public errorHandler: any,
        private portalService: OldPortalService,
        private sharedService: SharedService,
        private toaster: ToasterService,
        private blahService: BlahService) { }

    ngOnInit() {
        this.blahService.localeChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.getUnexpiredNews());

        this.getUnexpiredNews();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
    * Gets unexpired news
    */
    private getUnexpiredNews() {
        this.currentLocale = this.blahService.currentLocale;

        //Clears arrays (needed in case if language is changed)
        this.allNotificationItems = [];
        this.uncheckedNews = [];
        this.unexpiredNews = [];

        this.route.paramMap.subscribe(params => {
            this.productId = params.get('productId');
            switch (this.productId) {
                case "107":
                    this.productTitle = this.blahService.blah('portal.news.portal', this.currentLocale);
                    break;
                case "503":
                    this.productTitle = this.blahService.blah('portal.news.memorandum', this.currentLocale);
                    break;
            }

            this.sharedService.hasProductUsageRight(params.get('productId') as unknown as number).subscribe(
                data => {

                    if (data === false) {
                        this.loading = false;
                        this.authorized = false;
                    }
                    else {
                        this.portalService.getUnexpiredNews(params.get('productId'), this.currentLocale.substr(0, 2)).pipe(mergeMap(data => {
                            this.parseNotifications(this.unexpiredNews, data.value);
                            return this.getUncheckedNews();
                        })).subscribe(data => {
                            this.parseNotifications(this.uncheckedNews, data.value);
                            this.initNotifications();
                            this.loading = false;
                        }, error => {
                            this.loading = false;
                            this.errorHandler.handleError(error);
                        });
                    }
                }, error => {
                    this.loading = false;
                    this.errorHandler.handleError(error);
                });
        });
    }

    /**
    * Inits notifications
    */
    private initNotifications() {
        this.unexpiredNews.forEach(val => {
            if (this.uncheckedNews.some(n => n.eventId == val.eventId)) {
                val.isUnchecked = true;
                val.isSelected = false;
            }
            this.allNotificationItems.push(val);
        });

        this.checkedArray = this.allNotificationItems.filter(d => d.isUnchecked === true).sort((a, b) => b.startDate.localeCompare(a.startDate));
        this.unCheckedArray = this.allNotificationItems.filter(d => d.isUnchecked === false).sort((a, b) => b.startDate.localeCompare(a.startDate));

        this.unReadNotificationsCount = this.allNotificationItems.filter((x, i) => { return x.isUnchecked; }).length;
        this.noOfPages = Math.ceil(this.allNotificationItems.length / this.itemsPerPage);
        this.changePage(this.currentPage);
    }

    /**
    * Parses notifications
    * @param array destination array of notifications
    * @param notifications source array of notifications
    */
    private parseNotifications(array: Array<Notification>, notifications: any) {
        for (let notification of notifications) {
            let newNotification = new Notification();
            newNotification.objectId = notification.ObjectId;
            newNotification.title = notification.Title;
            newNotification.content = notification.Content;
            newNotification.created = notification.Created;
            newNotification.startDate = notification.StartDate;
            newNotification.eventId = notification.EventId;
            newNotification.important = notification.Important;
            newNotification['dateText'] = this.notificationDateText(notification.StartDate);

            array.push(newNotification);
        }
    }

    /**
    * Copies notification link
    * @param e Event
    * @param notificationId Notification id
    */
    copyLink(e: Event, notificationId: string) {
        e.stopPropagation();
        const notificationLink = `${document.baseURI}Notification/${notificationId}`;

        navigator.clipboard.writeText(notificationLink);

        this.toaster.create(this.blahService.blah('portal.linkCopied'));
    }

    /**
    * Routes to Portal
    */
    openPortal() {
        this.router.navigate(['/']);
    }

    /**
    * Marks notification as read
    * @param notification Notification
    */
    markAsRead(notification: Notification) {
        this.portalService.markNotificationAsRead(notification.objectId).subscribe(data => {
            notification.isUnchecked = false;

            this.unReadNotificationsCount = this.unReadNotificationsCount - 1;
            this.checkedArray = this.allNotificationItems.filter(d => d.isUnchecked === true).sort((a, b) => b.startDate.localeCompare(a.startDate));
            this.unCheckedArray = this.allNotificationItems.filter(d => d.isUnchecked === false).sort((a, b) => b.startDate.localeCompare(a.startDate));

            this.allNotificationItems = [];
            this.allNotificationItems = this.checkedArray.concat(this.unCheckedArray);

        }, error => {
            console.error('Failed to mark notification as read (probably was marked already)');
        });

    }

    /**
    * Marks news as read
    */
    public markNewsAsRead() {

        let markNewsAsReadParameters = new MarkNewsAsReadParameters();

        markNewsAsReadParameters.ProductId = Number(this.productId);

        if (this.unReadNotificationsCount > 0) {
            this.portalService.markNewsAsRead(markNewsAsReadParameters)
                .subscribe(
                    data => {

                        this.allNotificationItems.forEach(element => {
                            element.isUnchecked = false;
                        });

                        this.unReadNotificationsCount = 0;

                    }, error => {
                        this.errorHandler.handleError(error);
                    });
        }
    }

    /**
    * Opens notification
    * @param notification Notification
    */
    openNotification(notification: Notification) {
        if (notification) {
            if (notification.isUnchecked == true) {
                notification.isUnchecked = false;

                this.unReadNotificationsCount = this.unReadNotificationsCount - 1;
                this.checkedArray = this.allNotificationItems.filter(d => d.isUnchecked === true).sort((a, b) => b.startDate.localeCompare(a.startDate));
                this.unCheckedArray = this.allNotificationItems.filter(d => d.isUnchecked === false).sort((a, b) => b.startDate.localeCompare(a.startDate));

                this.allNotificationItems = [];
                this.allNotificationItems = this.checkedArray.concat(this.unCheckedArray);
            }
            this.router.navigate(['/Notification/' + notification.objectId]);
        }
    }

    /**
    * Event method for notification marked as read
    * @param e Event
    * @param notification Notification
    */
    onNotificationMarkedAsRead(e: Event, notification: Notification) {
        e.stopPropagation();

        if (notification) {
            this.markAsRead(notification);
        }
    }

    /**
    * Sets notification date text
    * @param date date
    */
    notificationDateText(date: string) {
        let dateTime = new Date();
        let dateToday = dateTime.setHours(0, 0, 0, 0);
        let yesterDay = new Date(dateTime.setDate(dateTime.getDate() - 1)).setHours(0, 0, 0, 0);
        let notificationDate = new Date(date).setHours(0, 0, 0, 0);
        let dateText = '';

        if (notificationDate == dateToday) {
            dateText = 'today';
        }
        if (notificationDate == yesterDay) {
            dateText = 'yesterDay';
        }
        return dateText;
    }

    /**
    * Gets unchecked news
    */
    private getUncheckedNews() {
        return this.portalService.getUncheckedNews(this.productId, this.currentLocale.substr(0, 2));
    }

    public prevPage() {
        if (this.currentPage > 1) {
            this.changePage(--this.currentPage);
        }
    }

    public nextPage() {
        if (this.currentPage < this.noOfPages) {
            this.changePage(++this.currentPage);
        }
    }

    private changePage(page) {
        this.pageItems = this.allNotificationItems.slice((page - 1) * this.itemsPerPage, (page * this.itemsPerPage));
    }
}
