import { Component } from '@angular/core';

@Component({
    template: `
<hpo-notification
    color="warn"
    [displayLink]="true"
    linkText="{{ 'default.toFrontPage' | blah }}"
    linkHref="/">

    {{ 'default.accessDenied' | blah }}
</hpo-notification>

    `,
})
export class AccessDeniedComponent { }
